import { useContext } from "react"
import { Navigate } from "react-router-dom"
import AuthContext from "../context/authContext/authContext"
import { getToken } from "./getToken"

const ProtectedRouter = ({children}:any) => {
  const {state, dispatch} = useContext(AuthContext)
  const sesion = getToken()
  if (!sesion) return <Navigate to="/login" />
  return <>{children}</>
}

export default ProtectedRouter


