import { Outlet } from "react-router-dom"
import {
  PageTitle,
  PageContainer,
  PageContent
} from "../../components";

const PaymentScreen = () =>{

  return(
    <PageContainer>
        <PageTitle>Facturación y pagos</PageTitle>
        <PageContent>
            <Outlet />
        </PageContent>
    </PageContainer>
  )
}

export default PaymentScreen;
