import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const LinkSideBar = ({ _id, path, name, handleActiveModule, currentModule }: any) => {

    return (
        <Link to={path} onClick={() => handleActiveModule(name)}>
            <li id={_id} className={(currentModule === name) ? "selected" : ''}>
                <Tooltip title={name} placement="right-end" arrow>
                    <img alt={name} />
                </Tooltip>
                <span>{name}</span>
                <ArrowForwardIosIcon className="arrowRigthBlue" style={{ height: "18px", color: "#093c5d" }} />
            </li>
        </Link>
    );
}

export default LinkSideBar;
