import styles from '../../styles/Notificaciones/Notificaciones.module.css';
import stylesCompanies from './Companies.module.css';
import { useState, useEffect } from 'react';
import { getCompanies } from '../../Services/companies/companies.services';
import moment from 'moment';
import { ErrorAlert } from '../../components';

function CompaniesSection(){
  const [search, setSearch] = useState('');
  const [companies, setCompanies] = useState([]);
  const [filterItems, setFilterItems] = useState([]);

  const onSearch = (e:any) => {
    setSearch(e.target.value);
    if(e.target.value === ''){
      setFilterItems(companies);
    }else{
      const fields = ['rfc', 'Name', 'fechaInicioContrato', 'fechaFinContrato'];
      const data = companies.filter((company:any)=>{
        for(const field of fields){
          if(company[field] && company[field].toLowerCase().includes( e.target.value.toLowerCase()) ){
            return true;
          }
        }
      });
      setFilterItems(data);
    }

  }

  useEffect(()=>{
    getCompaniesData();
  }, []);

  const getCompaniesData = async() => {
    const data = await getCompanies();
    if(!data.error){
      setCompanies(data);
      setFilterItems(data);
    }    
  }

  return (
    <div className={stylesCompanies.tableContainer}>
      <div className={styles.contenedorTablas}>
        <div className={styles.contenedorTituloTabla} >
          <div className={styles.contenedorInput}>
            <div>
              <input
                value={search}
                className={styles.inputFiltrado}
                type="text"
                placeholder="Ingresa el nombre, rfc o fecha"
                onChange={onSearch}
                />
            </div>
            <div className={styles.iconoFiltro}></div>
          </div>
        </div>
        <div className={styles.contenedorTablaInterno}>
          <div className={styles.espacioTabla}>
            <table className={styles.tabla}>
              <thead>
                <tr>
                <td className={styles.columnaTabla}>Nombre</td>
                <td className={styles.columnaTabla}>RFC</td>
                <td className={styles.columnaTabla}>Fecha inicio</td>
                <td className={styles.columnaTabla}>Fecha final</td>
                <td className={styles.columnaTabla}>Expediente</td>
                <td className={styles.columnaTabla}>Facturación</td>
                <td className={styles.columnaTabla}>Status</td>
              </tr>
            </thead>
              <tbody>
                {
                  filterItems?.map((company:any, key:number)=>(
                    <tr className={styles.lineaTitulo} key={key}>
                      <td className={`${styles.columnaTexto}`}>{company.Name}</td>
                      <td className={`${styles.columnaTexto}`}>{company.rfc}</td>
                      <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{ moment(company.fechaInicioContrato).format('DD-MM-YYYY') }</td>
                      <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{ moment(company.fechaFinContrato).format('DD-MM-YYYY') }</td>
                      <td className={styles.columnaDatos}>
                        <div className={styles.botonLink} onClick={() => ErrorAlert({text: 'La funcion no se encuentra disponible.'})}>
                          <div className={styles.contenedorBoton}>
                            <div className={styles.iconoDocumento}></div>
                          </div>
                        </div>
                      </td>   
                      <td className={styles.columnaDatos}>
                        <a href={`/payment/${company.code}/${company.id}`} className={styles.botonLink}>
                          <div className={styles.contenedorBoton}>
                            <div className={styles.iconoFacturacionPagos}></div>
                          </div>
                        </a>
                      </td>   
                      <td className={styles.columnaDatos}>
                        <a href={``} className={styles.botonLink}>
                          <div className={styles.contenedorBoton}>
                            <div className={styles.iconoAceptado}></div>
                          </div>
                        </a>
                      </td>            
                    </tr>
                    )
                  )
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CompaniesSection;