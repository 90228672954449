import { Outlet } from "react-router-dom"
import {
  PageTitle,
  PageContainer,
  PageContent
} from "../../components"

const CentralPagosScreen = () =>{
  return(
    <PageContainer>
      <PageTitle>Central de pagos</PageTitle>
      <PageContent>
        <Outlet />
      </PageContent>      
    </PageContainer>
  )
}

export default CentralPagosScreen;