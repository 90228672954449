import "./PageTitle.css";

const PageTitle = (props: any) => {
    return (
        <div className="PageTitle">
            {props.children}
        </div>
    );
};

export default PageTitle;
