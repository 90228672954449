import axios from 'axios';

export const getTransactions = async(filtro:string)=>{
  try{
    let res = await axios.get(`/transactions?filtro=${filtro}`,{
      headers:{
        ['authorization']: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return res.data;
  }catch(err:any){
    const {error}= err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}

export const uploadFilesTransactions = async(data:any)=>{
  try{
    let res = await axios.post(`/transactions/upload`,data,{
      headers:{
        ['authorization']: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return res.data;
  }catch(err:any){
    const {error}= err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}

export const sendEmailTransactions = async(data:any)=>{
  try{
    let res = await axios.post(`https://bits-apidev-uat.azurewebsites.net/api/admin/sendEmail`,data);
    return res.data;
  }catch(err:any){
    const {error}= err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}