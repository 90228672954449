import {Types} from "./authContext"

const AuthReducer = (state:any, action:any)=>{
  const {payload, type} = action
  switch(type){
    case Types.LOGIN:
      return{
        ...state,
        logged: true,
        Nombre: payload.Nombre,
        Imagen: payload.Imagen
      }
    break;
    case Types.CAMBIO_ESTATUS:
      return{
        ...state,
        cambioEstatus: payload.cambioEstatus
      }
    default:
    return state
  }
}


export default AuthReducer