import { useContext, useEffect, useState } from 'react'
import _ from 'lodash'
import styles from '../../../styles/Notificaciones/Notificaciones.module.css'
import { AlertaComentario, SuccessfulAlert, Loading, ToolBar } from '../../../components'
import iconoPDF from "./../../../assets/svg/icono_descargar_pdf.svg"
import descargaXml from "./../../../assets/svg/icono_descarga_xml.svg"
import CreateClientForm from './CreateClientForm/CreateClientForm';
import { getCompany, createPaymentCompany, getCollaboratorCount } from '../../../Services/companies/companies.services';
import { useParams } from 'react-router-dom'
import {Link} from 'react-router-dom'


const ClientListSection = () =>{
  const [mainData, setMainData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [lastBillData, setLastBillData] = useState<any>({})
  const [elementosPagina, setElementosPagina] = useState(10)
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [loading, setLoading] = useState(true);
  const [company, setCompany] = useState({
    Name: '',
    rfc: ''
  });
  const [listaMeses, setListaMeses] = useState<any[]>([]);
  const [mesSeleccion, setMesSeleccion] = useState({
    Mes: 'seleccione',
    Total: ''
  });
  const [selected, setSelected] = useState(false)

  const { db, companyId } = useParams();
  
  useEffect(() => {
    getCompanyData();
    getCollaboratorTotal();
  }, [companyId]);

  const getCompanyData = async () => {
    const result = await getCompany(db || '', Number(companyId));
  console.log('Este es el resultado inicial', result)

    if(!result.error){
      setMainData(result.payments);
      setItems( result.payments );
      setCompany( result.company[0] );
    }
    setLoading(false);
  }

  const getCollaboratorTotal = async () => {
    const result = await getCollaboratorCount(db || '');
    setListaMeses(result);
    setLoading(false);
  }

  const filterFunction = (mainData: any[], searchTerm: String) => {
    let expression = new RegExp(`${searchTerm}.*`, "i");
    console.log('Este es el mainData', mainData)
    
    const nuevofilteredData = mainData.filter((item: any) => {
      // Convertir la fecha a un formato m�s amigable
      const fechaISO = new Date(item.Fecha);
      const dia = fechaISO.getDate().toString().padStart(2, '0');
      const mes = (fechaISO.getMonth() + 1).toString().padStart(2, '0'); // Enero es 0
      const anio = fechaISO.getFullYear();
      const fechaAmigable = `${dia}/${mes}/${anio}`;
  
      // Revisar si el t�rmino de b�squeda coincide con alguna de las propiedades
      return expression.test(item.Mes) ||
             expression.test(item.Total.toString()) ||
             expression.test(item.TotalCollaborators.toString()) ||
             expression.test(fechaAmigable) ||
             expression.test(item.UUID);
    });
  
    return nuevofilteredData;
  };

  const createClient = async (data: any) => {
    setLoading(true);
    
    const result = await createPaymentCompany({
        UUID: data.UUID,
        PDF: data.PDF,
        XML: data.XML,
        Fecha: data.Fecha,
        db: db || '',
        totalColaboradores: parseFloat(mesSeleccion.Total),
        Mes: mesSeleccion.Mes,
        Total: parseFloat(data.Total)
        
    });
    

    if(!result.error){
      getCompanyData();
      getCollaboratorTotal();
    }else{
      setLoading(false);
    }
    
   console.log('Este es el resultado de data', data)
  }

  /// Start Commun functionalities

  const defineElementosPagina = (valor: number)=>{
    setElementosPagina((current) => current = valor)
    setNumeroPagina((current) => current = 1)
  }

  const cambioPagina = (direction: any) => {
    if (direction === 'back' && numeroPagina > 1) {
      setNumeroPagina(numeroPagina - 1);
    } else if (direction === 'next' && numeroPagina < Math.ceil(items.length / elementosPagina)) {
      setNumeroPagina(numeroPagina + 1);
    }
  }

  function getPaginatedItems(items: any, page: any, pageSize: any) {
    var pg = page || 1,
      pgSize = pageSize || 100,
      offset = (pg - 1) * pgSize,
      pagedItems = _.drop(items, offset).slice(0, pgSize);
    return {
      page: pg,
      pageSize: pgSize,
      total: items ? items.length : 0,
      total_pages: Math.ceil(items ? items.length : 0 / pgSize),
      data: pagedItems
    };
  }

  const search = (searchTerm: String) =>{
    let results = _.isNil(searchTerm) && _.isEmpty(searchTerm) ? mainData 
      : filterFunction(mainData, searchTerm);
    setItems(results);
    setNumeroPagina((current) => current = 1)
  }

  const renderPaginationDetails = () => {
    let pageInitial = (numeroPagina-1) * elementosPagina;
    let pageFinal = pageInitial + elementosPagina;
    if (pageFinal > items.length) {
      pageFinal = items.length
    }
    return (
      <>
        {`${pageInitial <= 0 ? 1 : pageInitial} - ${pageFinal} de ${items.length}`}
      </>
    );
  };

  const handleMesSeleccion = ({Mes,Total}:any) => {
    setSelected(true)
    setMesSeleccion({Mes,Total})
  }

  const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Los meses en JavaScript empiezan en 0
    const year = date.getFullYear();
  
    return `${day}/${month}/${year}`;
  };


  /// End Commun functionalities

  return(
    <>
    {(loading) ? <div className={styles.contenedorTablas}> <Loading /> </div>
    :
    <div className={styles.contenedorTablas}>
      <div className={styles.contenedorTituloTabla} >
        <div className={styles.tituloTabla}>
          { company.Name+" "+ (company.rfc || '') }
        </div>
        <div className={styles.fp_contenedorSelect}>
          <div>
            <select className={styles.fp_select} onChange={(e) => handleMesSeleccion(JSON.parse(e.target.value))}>
              <option value="" disabled={selected}>Seleccione un mes</option>            
              {listaMeses.map((total: any) => (
                <option value={JSON.stringify({Mes: total.Mes, Total:total.Total})}>{total.Mes}</option>
              ))}
            </select>
          </div>
          <div className={styles.fp_contenedorTotalExterno}>
            <div className={styles.fp_tituloTotal}>
              <span>Total Colaboradores:</span>
            </div>
            <div className={styles.fp_contenedorTotal}>
              <div>{mesSeleccion.Total}</div>
            </div>
          </div>
        </div>
        <ToolBar>
          <div className={styles.contenedorInputDos} style={{marginLeft: '0'}}>
            <div>
              <input
                className={styles.inputFiltradoDos}
                type="text"
                placeholder="Buscar mes factura"
                onChange={(e) => search(e.target.value)}
              />
            </div>
          </div>
          <CreateClientForm createRecord={createClient} cliente={company.Name} mes={mesSeleccion.Mes} />
        </ToolBar>
      </div>
      <div className={styles.contenedorTablaInterno}>
      <div className={styles.espacioTabla}>
      <table className={styles.tabla}>
        <thead>
          <tr>
            <td className={styles.columnaTabla}>Mes</td>
            <td className={styles.columnaTabla}>Total</td>
            <td className={styles.columnaTabla}>Usuario</td>
            <td className={styles.columnaTabla}>Fecha Factura</td>
            <td className={styles.columnaTabla}>UUID</td>
            <td className={styles.columnaTabla}>PDF</td>
            <td className={styles.columnaTabla}>XML</td>
            <td className={styles.columnaTabla}>Estatus</td>
          </tr>
        </thead>
        <tbody>
          {
            getPaginatedItems(items, numeroPagina , elementosPagina ).data?.map((item: any, key:number)=>(
              <tr className={styles.lineaTitulo} key={key}>
                <td className={`${styles.columnaTexto}`}>{item?.Mes}</td>
                <td className={`${styles.columnaTexto}`}>{item?.Total.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}</td>
                <td className={`${styles.columnaTexto} ${styles.columnaDatos}`}>{item?.TotalCollaborators}</td>
                <td className={`${styles.columnaTexto}`}>{formatDate(item?.Fecha)}</td>
                <td className={`${styles.columnaTexto} ${styles.columnaDatos}`}>{item?.UUID}</td>
                <td className={styles.columnaTexto}>
                  <div className={styles.contenedorBoton}>
                    <a
                      style={{ marginRight: "auto", marginLeft: "auto" }}
                      target="_blank"
                      href={item?.PDF}
                    >
                      <img
                        src={iconoPDF}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                        alt="DownloadFile"
                      />
                    </a>
                  </div>
                </td>

                <td className={styles.pc_columnaDatos}>
                  <div className={styles.contenedorBoton}>
                    <a
                      style={{ marginRight: "auto", marginLeft: "auto" }}
                      target="_blank"
                      href={item?.XML}
                    >
                      <img
                        src={descargaXml}
                        style={{ marginRight: "auto", marginLeft: "auto" }}
                        className="cursor"
                      />
                    </a>
                  </div>
                </td>
                <td className={styles.columnaTexto}>
                  {(item?.statusDispersion === 'LIQUIDADO')?
                    <div className={styles.fp_contenedorPagado}>
                      <div className={styles.iconoPagado}></div>
                      <div className={styles.textoBotonPagado}>
                        Pagado
                      </div>
                    </div>
                    :
                    (item?.statusDispersion === 'ENESPERA') ?
                      <div 
                        className={styles.fp_contenedorPagar}
                      >
                        <div className={styles.iconoAgegarPago}></div>
                          <span>Pendiente</span>
                      </div>
                      :
                      (item?.statusDispersion === 'ERROR' || item?.statusDispersion === 'DEVUELTO') ?
                        <div className={styles.fp_contenedorReintentar}>
                          <div className={styles.fp_contenedorInternoReintentar}>
                            <div className={styles.pd_iconoError}></div>
                            <div className={styles.fp_textoError}>Error al Pagar</div>

                        </div>
                       <div 
                         className={styles.fp_textoMensajeDispersion}
                         >{item?.mensajeDispersion}</div>
                     </div>
                   :null
                  }
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
      </div>
      


      <div className={styles.contenedorPaginacion}>
        <div className={styles.columnaUnoPaginacion}>
          {renderPaginationDetails()}
        </div>
        <div className={styles.columnaDosPaginacion}>
          <div
            className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}
            onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}
          >
            Mostrar por p&aacute;gina {elementosPagina}
            <div className={styles.iconoPaginacion}></div>
            <div className={styles.contenedorListaElementos}>
              {(muestraListaPaginacion === true)?
                <ul className={styles.listaElementos}>
                  <li onClick={()=> defineElementosPagina(10)}>10</li>
                  <li onClick={()=> defineElementosPagina(20)}>20</li>
                  <li onClick={()=> defineElementosPagina(25)}>25</li>
                </ul>
                :null
              }
            </div>
          </div>
          <div className={styles.columnaBotonesPaginacion}>
            <div
              className={styles.iconoPaginacionAnteriorActivo}
              onClick={() => cambioPagina('back')}
            ></div>
            <div className={styles.textoPaginacion}>
              {numeroPagina}/{Math.ceil(items.length/elementosPagina)}
            </div>
            <div
              className={styles.iconoPaginacionSiguienteActivo}
              onClick={()=> cambioPagina('next')}
            >
            </div>
          </div>
        </div>
      </div>

      </div>
                <div className={styles.pd_contenedorBarraEstado}>
            <div className={styles.pd_barraEstado} >
              <div className={styles.pd_contenedorRegreso}

              >
              <Link to ="/companies" className={styles.textoRegresar}>
                <div className={styles.pd_botonRegreso}></div>
                  Regresar a "Clientes"
              </Link>
              </div>
                <Link to="/companies" className={styles.botonSalir}
                >
                  Salir
                </Link>
            </div>
          </div>
    </div>
  }
    </>
  )

}

export default ClientListSection;