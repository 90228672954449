import './HeaderPrincipal.css';


const HeaderPrincipal = () =>{
  const imagenPerfil = localStorage.getItem('img')
  const nombrePerfil = localStorage.getItem('nombre')
  return(
    <div className={"contenedor"}>
      <div className={"logo"}>
        <div className={"logo_imagen"}></div>
      </div>
      <div className={"usuario"}>
        <div>
          <img src={imagenPerfil!}  className={"usuario__imagen"}></img>
        </div>
        <div className={"usuario__nombre"}>{nombrePerfil}</div>
      </div>
    </div>
  )
}

export default HeaderPrincipal;
