import { useReducer } from "react";
import AuthContext,{initialState} from "./authContext";
import AuthReducer from "./authReducer";

const AuthState = (props:any) => {
  const [state, dispatch] = useReducer(AuthReducer,initialState)

  return(
    <AuthContext.Provider value={{state,dispatch}}>
      {props.children}
    </AuthContext.Provider>
  )
}

export default AuthState