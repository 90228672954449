import styles from '../../../styles/Notificaciones/Notificaciones.module.css'
import { useContext, useEffect, useState } from 'react'
import { getDescargaArchivo, getMovimientosPendientes, registraRespuestaIDSE } from '../../../Services/Movimientos/movimientosService'
import {saveAs} from 'file-saver'
import File_Helper from '../../../helpers/File_Helper'
import NoProcesados from './NoProcesadosSection'
import AuthContext from '../../../context/authContext/authContext'
import { cambioEstatus } from '../../../context/authContext/Actions'
import AlertaEnvioConfirma from '../../../components/alerta/AlertaEnvio'
import { useQuery } from '@apollo/client'
import { GET_MOVIMIENTOSPENDIENTES } from '../../../querys/querys'
import { CreateExcelFile } from '../../../helpers/excel_alta'
import { PageSection, AlertaEnviado, AlertaFaltaRespuesta, ErrorAlert, AlertaMensaje, Loading } from '../../../components'

const NotificacionesSection = () =>{
  const {state, dispatch} = useContext(AuthContext)
  const [movimientosPendientes, setMovimientosPendientes] = useState<any[]>([])
  const [estadoRespuesta, setEstadoRespuesta] = useState(true)
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [items, setItems] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [elementosPagina, setElementosPagina] = useState(10)
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [loading, setLoading] = useState(false)

  const {data,error,loading:loadingMovimientos, startPolling, stopPolling} = useQuery(GET_MOVIMIENTOSPENDIENTES)
  const resultado = data?.GET_MOVIMIENTOSPENDIENTES

  const getMovimientos = async() =>{
    setMovimientosPendientes([...resultado])
    setLoading((current) => current = false)
    console.log('entras una ves')
  }

  useEffect(()=>{
    if (movimientosPendientes) {
      guardaPaginado()
    }
  },[movimientosPendientes, elementosPagina])

  useEffect(()=>{
    console.log('MOVIMIENTOS PENDIENTES')
    getMovimientos()
  },[])

  const busqueda = (e:any) =>{
    setSearch(e.target.value)
    guardaPaginado()
  }

  const guardaPaginado = () => {
    const results = !search ? movimientosPendientes : movimientosPendientes?.filter(
      (dato) => (dato.Nombre.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.Empresa.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.tipo.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.IMSS.toLowerCase().includes(search.toLocaleLowerCase()))
    )
    setFiltrado((current) => current = results)
    setItems((current) => current = [...results].splice(0, elementosPagina))
    setNumeroPagina((current) => current = 1)
  }

  const siguientePagina = () => {
    const totalElementos = filtrado.length;
    const nextPage = currentPage +1
    const firstIndex =  nextPage * elementosPagina
    if(firstIndex === totalElementos) return;
    setItems((current) => current = [...filtrado].splice(firstIndex, elementosPagina))
    setCurrentPage((current)=> current = nextPage)
  }

  const paginaAnterior = () => {
    const prevPage = currentPage - 1
    if(prevPage < 0 ) return;
    const firstIndex = prevPage * elementosPagina
    setItems((current) => current = [...filtrado].splice(firstIndex, elementosPagina))
    setCurrentPage((current)=> current = prevPage)
  }

  const descargaArchivos = async(id:any,Tipo:any)=>{
    const result = await getDescargaArchivo({id,Tipo})
    const blob = new Blob([result.result[0]["Resultado"]], {type:'text/plain;charset=utf-8'})
    saveAs(blob, 'miarchivo.txt')
  }

  const noProcesado = async (id:any,tipo:string, nombre:string, empresa:string) =>{
    AlertaMensaje(id, tipo, nombre, empresa, setEstadoRespuesta, dispatch, state.cambioEstatus, setMovimientosPendientes, movimientosPendientes)
  }

  const alertaFaltaRegistro = async()=>{
    ErrorAlert({text: 'Por favor seleccione una respuesta'})
  }

  const registraEnvioIDSE = async(id:any, tipo:string, nombre:string, empresa:string)=>{
    AlertaEnvioConfirma(id,tipo,nombre,empresa, dispatch, setMovimientosPendientes, movimientosPendientes, setEstadoRespuesta, estadoRespuesta)
  }

  const cambioPagina = (sentido:string) =>{
    if(sentido === 'Anterior'){
      let nuevaPagina = numeroPagina - 1
      if(nuevaPagina < 1){
        setNumeroPagina((current) => current = 1)
      }else{
        setNumeroPagina((current) => current = nuevaPagina)
      }
      paginaAnterior()
    }else{
      console.log('resultados',filtrado.length, (numeroPagina * elementosPagina) )
      if(filtrado.length <=(numeroPagina* elementosPagina)){
        return;
      }else{
      let nuevaPagina = numeroPagina + 1
        setNumeroPagina((current) => current = nuevaPagina)
        siguientePagina()
      }
    }
  }

  const defineElementosPagina = (valor: number)=>{
    setElementosPagina((current) => current = valor)
    setNumeroPagina((current) => current = 1)
  }


  useEffect(()=>{
    if(state.cambioEstatus === true){
      guardaPaginado()
      cambioEstatus({cambioEstatus:false},dispatch)
      setLoading((current) => current = false)
    }
  },[state.cambioEstatus])

  useEffect(()=>{
    getMovimientos()
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[resultado,startPolling, stopPolling])
  
  console.log('movimientos pendientes', resultado)

  return(
    <PageSection>
    {(loading) ? <div className={styles.contenedorTablas}> <Loading /> </div>
    :
      <div className={styles.contenedorTablas}>
        <div className={styles.contenedorTituloTabla} >
          <div className={styles.tituloTabla}>
            Movimientos pendientes
          </div>
          <div className={styles.contenedorInput}>
            <div>
              <input
                value={search}
                className={styles.inputFiltrado}
                type="text"
                placeholder="Ingresa el Tipo de movimiento/Nombre/NSS o Razon Social"
                onChange={busqueda}
                />
            </div>
            <div className={styles.iconoFiltro}></div>
          </div>
        </div>
        <div className={styles.contenedorTablaInterno}>
        <div className={styles.espacioTabla}>
        <table className={styles.tabla}>
          <thead>
            <tr>
            <td className={styles.columnaTabla}>Tipo de Movimiento</td>
            <td className={styles.columnaTabla}>Nombre Colaborador</td>
            <td className={styles.columnaTabla}>NSS</td>
            <td className={styles.columnaTabla}>Razon Social</td>
            <td className={styles.columnaTabla}>Documento del movimiento</td>
            <td className={styles.columnaTabla}>Certificado</td>
            <td className={styles.columnaTabla}>Llave</td>
            <td className={styles.columnaTabla}>Usuario</td>
            <td className={styles.columnaTabla}>Contrase&ntilde;a</td>
            <td className={styles.columnaTabla}>Archivo</td>
            <td className={styles.columnaTabla}>Respuesta</td>
          </tr>
        </thead>
        <tbody>
          {
            items?.map((movimiento)=>(
              <tr className={styles.lineaTitulo}>
                <td className={`${styles.columnaTexto}`}>{movimiento.tipo}</td>
                <td className={`${styles.columnaTexto}`}>{movimiento.Nombre}</td>
                <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{movimiento.IMSS}</td>
                <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{movimiento.Empresa}</td>
                <td className={styles.columnaDatos}>
                  <div
                    className={styles.contenedorBoton}
                    //onClick={()=>descargaArchivos(movimiento.id, movimiento.tipo)}
                      onClick={()=> CreateExcelFile(movimiento, 'Activo')}
                    >
                    <div className={styles.iconoDocumento}></div>
                  </div>
                </td>
                <td className={styles.columnaDatos}>
                  <a href={movimiento.LlaveIMSS} className={styles.botonLink}>
                    <div className={styles.contenedorBoton}>
                      <div className={styles.iconoCertificado}></div>
                    </div>
                  </a>
                </td>
                <td className={styles.columnaDatos}>
                  <a href={movimiento.ContrasenaIMSS} className={styles.botonLink}>
                    <div className={styles.contenedorBoton}>
                      <div className={styles.iconoLlave}></div>
                    </div>
                  </a>
                </td>
                <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>
                  {movimiento.userIMSS}
                </td>
                <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>
                  {movimiento.certificadoPass}
                </td>
                <td className={styles.botonesEstado}>
                  <div
                    className={styles.contenedorBoton}
                    onClick={()=>noProcesado(movimiento.id, movimiento.tipo, movimiento.Nombre, movimiento.Empresa)}
                    >
                    <div className={styles.iconoNoProcesado}></div>
                  </div>
                  {(movimiento.Respuesta ==='EnEspera')?
                    <div
                      onClick={()=> registraEnvioIDSE(movimiento.id,movimiento.tipo, movimiento.Nombre, movimiento.Empresa)}
                      className={styles.contenedorBoton}>
                      <div className={styles.iconoEnviar}></div>
                    </div>
                    :
                    <div
                      className={styles.contenedorBoton}
                      onClick={()=> AlertaFaltaRespuesta()}>
                      <div className={styles.iconoEnviarDesactivado}></div>
                    </div>
                  }

                </td>
                {(movimiento.Respuesta === 'EnEspera' && movimiento.envioIDSE === "false" )?
                  <td className={styles.columnaDatosDesactivado}>
                    <div
                      className={styles.contenedorBoton}
                      onClick={() => alertaFaltaRegistro()}
                      >
                      <div className={styles.iconoSubirRespuestaDesactivado}></div>
                    </div>
                  </td>
                  :
                <td className={styles.columnaDatos}>
                  <File_Helper
                    name="ArchivoEnvioIDSE"
                    parametrofrom="ArchivoENVIOIDSE"
                    idUsr={movimiento.id}
                    Desde= 'Enviado'
                  />
                </td>
                }
              </tr>
              )
            )
          }

        </tbody>
        </table>
        </div>
        <div className={styles.contenedorPaginacion}>
          <div className={styles.columnaUnoPaginacion}>
            {numeroPagina} -{elementosPagina} de {Math.ceil(filtrado.length/elementosPagina)}
          </div>
          <div className={styles.columnaDosPaginacion}>
            <div
              className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}
              onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}>
            Mostrar por p&aacute;gina {elementosPagina}
            <div className={styles.iconoPaginacion}></div>
            <div className={styles.contenedorListaElementos}>
              {(muestraListaPaginacion === true)?
                <ul className={styles.listaElementos}>
                  <li onClick={() => defineElementosPagina(3)}>10</li>
                  <li onClick={() => defineElementosPagina(5)}>20</li>
                  <li onClick={() => defineElementosPagina(2)}>25</li>
                </ul>
                :
                null
            }
            </div>
            </div>
            <div className={styles.columnaBotonesPaginacion}>
              <div
                className={styles.iconoPaginacionAnteriorActivo}
                onClick={() =>cambioPagina('Anterior')}
                ></div>
              <div className={styles.textoPaginacion}>
                {numeroPagina}/{Math.ceil(filtrado.length/elementosPagina)}
              </div>
              <div
                className={styles.iconoPaginacionSiguienteActivo}
                onClick={()=>cambioPagina('Siguiente')}
                ></div>
            </div>
          </div>
        </div>
        </div>
      </div>
  }
    </PageSection>
  )
}

export default NotificacionesSection;


