import { PageSection } from "../../../components";
import NotificacionesSection from "./NotificacionesSection";
import NoProcesadosSection from "./NoProcesadosSection";


const NewMovementsScreen = () =>{

  return(
    <>
      <NotificacionesSection />
      <NoProcesadosSection />
    </>
  )
}

export default NewMovementsScreen;
