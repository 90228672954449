import axios from 'axios'

export const getMovimientosPendientes = async() =>{
  let res = await axios.get('/movimientos/pendientes',{
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}
export const getMovimientosEnviados = async()=>{
  let res = await axios.get('/movimientos/enviados',{
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    console.log('resultado', res)
    return res.data.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const getMovimientosTodos = async()=>{
  let res = await axios.get('/movimientos/todos',{
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    console.log('resultado', res)
    return res.data.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const getNoProcesados = async()=>{
  let res = await axios.get('/movimientos/noprocesado',{
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}


type respuestaImss={
  idMovimiento: number | null,
  respuestaIDSE: string  | null,
  Desde: string | null
}

export const registraRespuestaIMSS = async(respuesta: respuestaImss) =>{

  console.log('respuesta',respuesta)
  let res = await axios.post('/movimientos/respuestaidse', respuesta,{
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data
  })
  .catch((err)=>{
    const {error}= err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
}


type descargaDocumento={
  id: number | null,
  Tipo: string |null
}
export const getDescargaArchivo = async(descarga: descargaDocumento) =>{
  let res = await axios.post('/movimientos/descarga', descarga, {
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

type respuestaIDSE ={
  id: number | null,
  respuesta: string | null
}

export const registraRespuestaIDSE = async(respuesta:respuestaIDSE)=>{
  console.log('hola')
  let res = await axios.post(`/movimientos/respuesta`, respuesta, {
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data.data
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const registraEnvioIDSE = async(id:number)=>{
  let res = await axios.post(`/movimientos/movimientoenviado/?id=${id}`, {
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res=>{
    console.log('resultado', res)
    return res
  })
  .catch((err)=>{
    const{error} = err.response?.data || false;
    console.log(err)
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const postRegresaNoProcesado = async(id:number) =>{
  let res = await axios.post(`/movimientos/regresanoprocesado`,{id}, {
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res
  })
  .catch((err)=>{
    const{error} = err.response?.data || false;
    console.log(err)
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

type noProcesado = {
  id: number | null,
  Comentario: string | null
}

export const postNoProcesado = async(noProcesado:noProcesado)=>{
  console.log(noProcesado)
  let res = await axios.post('/movimientos/noprocesado', noProcesado, {
    headers:{
      ['authorization']: `Bearer ${localStorage.getItem('token')}`
    }
  })
  .then(res =>{
    return res.data.result
  })
  .catch((err)=>{
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const postFileAWS = async(
  e:any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean,
  section?: string
) =>{
  try{
    let file = new FormData();
    file.append("document", e.target.files[0]);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));
    if (section) file.append("section", section);


    const result = await axios.post(
      `https://bits-restapi-prd.azurewebsites.net/api/candidates/postRespuestaIDSE/file/${desde}/${id}/${e.target.name}`,
      file,{
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    )
    console.log('file',e.target.files[0])
    console.log('Resultado', result)
    return result.data.result;
  }catch(err){
    console.log(err)
  }
}

export const postFileAzurePDFPagos = async(
  e:any,
  id: string,
  desde: string,
  name?: string,
  remove_file?: boolean,
  section?: string,
  Cliente?: string,
  Mes?: string
) =>{
  try{
    let file = new FormData();
    file.append("document", e.target.files[0]);
    if (name) file.append("name", name);
    if (remove_file) file.append("remove_file", JSON.stringify(remove_file));
    if (section) file.append("section", section);
    const nombreArchivo = `${Cliente}-${Mes}`
    const nombreDescargble= `${Cliente}-${Mes}`

    const result = await axios.post(
      `https://bits-apidev-prd.azurewebsites.net/api/candidates/postpdfpagos/file/${nombreArchivo}/${id}/${nombreDescargble}`,
      file,{
        headers: {
          "Content-Type": `multipart/form-data;boundary=${file}`,
        },
      }
    )
    
    console.log('Este es el resultado', result.data.result)

    return result.data.result;
  }catch(err){
    console.log(err)
  }
}