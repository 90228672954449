import { ChangeEvent, useContext, useEffect, useState } from "react";
import moment from "moment";
import styles from '../../../../styles/Notificaciones/Notificaciones.module.css'
import "./CreateClientForm.css";
import FileUploader from "../../../../helpers/FileUploader";
import FileUploaderpdfPagos from "../../../../helpers/FileUploaderAzurePDF";
import FileUploaderxmlPagos from "../../../../helpers/FileUploaderAzureXML";
import { ErrorAlert } from "../../../../components";
import { create } from "lodash";

interface IValues {
    UUID: string;
    factura: string;
    XML: string | undefined;
    PDF: string | undefined;
    Fecha: string;
    showDate: boolean,
    Total: number,
    Mes: string,
}

let initialState = {
    UUID: '',
    factura: '',
    XML : '',
    PDF: '',
    Fecha:'',
    showDate: false,
    Total: 0,
    Mes: ''
}

const CreateClientForm = (props: any) => {
    const [values, setValues] = useState<IValues>(initialState);
    const [total, setTotal] = useState<string>('');
    const today = new Date();
    const dateString = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;

    const changeValues = (e: ChangeEvent<{ name: string, value: string }>): void => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        });
    }

    console.log('Este es el cliente', props.cliente, 'Este es el mes', props.mes)

    const handleScheduleDate = () => {
        setValues({
            ...values,
            showDate: true,
        });
    }

    const createPayment = () => {
        props.createRecord(values);
        setValues(initialState);
    }

    const recoverFilesURL = (name: string, value: string) => {
        console.log('Este es el valor',name, value);
        setValues({
            ...values,
            [name]: value
        });
    }

    const desformatCurrency = (value: string) => {
        return Number(value.replace(/[^0-9.-]+/g,""));
      }
      

    const changeValuesMonto = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTotal(value);
      };

      const handleBlur = () => {
        if(total === '') return;
        const desformattedValue = desformatCurrency(total);
        const formattedValue = desformattedValue.toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        });
      
        setTotal(formattedValue);
        setValues({
            ...values,
            Total: desformattedValue
        })
      };

    const alertFaltaMes = () => {
        ErrorAlert({text: 'Por favor seleccione una Mes'})
    }

    const limpiaArchivo = (Valor:string) => {
        setValues({
            ...values,
            [Valor]: ''
        })
    }

    return (
        <div>
            <table className={styles.tabla}>
                <thead>
                    <tr>
                        <td className={styles.columnaTabla}>UUID</td>
                        <td className={styles.columnaTabla}>PDF</td>
                        <td className={styles.columnaTabla}>XML</td>
                        <td className={styles.columnaTabla}>Fecha</td>
                        <td className={styles.columnaTabla}>Monto</td>
                        <td className={styles.columnaTabla}>Accion</td>
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.lineaTitulo}>
                        <td className={styles.columnaTexto}>
                            <input
                                id="standard-multiline-static"
                                className={styles.fp_inputUUID}
                                name="UUID"
                                placeholder='XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX'
                                onChange={(event) => {
                                    if (event.target.value.length > 36) {
                                        return;
                                      }
                                    let value = event.target.value;
                                    value = value.replace(/-/g, '');
                                    if (value.length > 8) {
                                      value = value.slice(0, 8) + '-' + value.slice(8);
                                    }
                                    if (value.length > 13) {
                                      value = value.slice(0, 13) + '-' + value.slice(13);
                                    }
                                    if (value.length > 18) {
                                      value = value.slice(0, 18) + '-' + value.slice(18);
                                    }
                                    setValues({...values, UUID: value});
                                  }}
                                value={values.UUID}
                            />
                        </td>
                        <td className={`${styles.columnaTexto}`} style={{ marginTop: '8px', marginBottom: '8px'}}>
                            {
                                (values.PDF === '')?
                                    (props.mes !== 'seleccione')?
                                        <FileUploaderpdfPagos
                                            name="PDF"
                                            parametrofrom="ArchivoENVIOIDSE"
                                            idUsr={0}
                                            Desde= 'Enviado'
                                            Cliente ={props.cliente}
                                            Mes = {props.mes}
                                            recoverFileURL={(url: string) => recoverFilesURL('PDF', url)}
                                        />
                                    :    
                                        <div className={styles.fp_archivoDesactivado} onClick={() => alertFaltaMes()}>
                                            <div className={styles.iconoSubirRespuestaDesactivado}></div>
                                        </div>
                                :   
                                <>
                                    <div className={styles.fp_botonDescargarArchivo}>
                                            <a
                                                href={values.PDF}
                                                target="_blank"
                                                className={styles.textobotonDescargaDocumento}
                                            >
                                                Ver PDF
                                            </a>
                                    </div>
                                    <div className={styles.fp_textoEliminarArchivo} onClick={() => limpiaArchivo('PDF')}>
                                        Eliminar  
                                    </div>
                                    </>
                            }
                        </td>
                        <td className={`${styles.columnaTexto}`} style={{ marginTop: '8px', marginBottom: '8px'}}>
                            {
                                (values.XML === '')?
                                    (props.mes !== 'seleccione')?
                                        <FileUploaderxmlPagos
                                            name="xml"
                                            parametrofrom="ArchivoENVIOIDSE"
                                            accept=".xml"
                                            idUsr={0}
                                            Desde= 'Enviado'
                                            Cliente ={props.cliente}
                                            Mes = {props.mes}
                                            recoverFileURL={(url: string) => recoverFilesURL('XML', url)}
                                        />
                                    :
                                    <div className={styles.fp_archivoDesactivado} onClick={() => alertFaltaMes()}>
                                        <div className={styles.iconoSubirRespuestaDesactivado}></div>
                                    </div>
                                :
                                <>
                                    <div className={styles.fp_botonDescargarArchivo}>
                                            <a
                                                href={values.XML}
                                                target="_blank"
                                                className={styles.textobotonDescargaDocumento}
                                            >
                                                Ver XML
                                            </a>
                                    </div>
                                    <div className={styles.fp_textoEliminarArchivo} onClick={() => limpiaArchivo('XML')}>

                                        Eliminar  
                                    </div>
                                    </>
                            }
                        </td>
                        <td className={`${styles.columnaTexto} ${styles.contenedorInput}`} style={{width: '105px', marginTop: '8px', marginBottom: '8px', marginRight: '20px'}}>
                            <label htmlFor="datetime-local" className={styles.inputLabel} id="dateLabel">
                                {values.Fecha !== '' ? '' : 'dd/mm/aaaa'}
                            </label>
                            <input
                                id="datetime-local"
                                name="Fecha"
                                className={styles.inputFiltradoFecha}
                                type='date'
                                value={values.Fecha !== '' ? values.Fecha : ''}
                                onFocus={handleScheduleDate}
                                onChange={(event) => {
                                    changeValues(event);
                                    const dateLabel = document.getElementById('dateLabel');
                                    if (dateLabel) {
                                        dateLabel.textContent = event.target.value !== '' ? '' : 'dd/mm/aaaa';
                                    }
                                }}
                                max={dateString}
                            />
                        </td>
                        <td>
                            <input
                                id="monto"
                                className={styles.fp_inputMonto}
                                name="monto"
                                placeholder='$0.00'
                                onChange={changeValuesMonto}
                                value={total}
                                onBlur={handleBlur}
                                onKeyPress={(event) => {
                                  if (!/[0-9\.]/.test(event.key)) {
                                    event.preventDefault();
                                  }
                                }}
                            />
                        </td>
                        <td>
                        <button
                            className={styles.fp_botonGuardar}
                            onClick={(event) => {
                            event.preventDefault();
                            if (!values.UUID) {
                                ErrorAlert({text: 'Por favor ingrese un UUID'});
                            } else if (!values.PDF) {
                                ErrorAlert({text: 'Por favor ingrese un PDF'});
                            } else if (!values.XML) {
                                ErrorAlert({text: 'Por favor ingrese un XML'});
                            } else if (!values.Fecha) {
                                ErrorAlert({text: 'Por favor ingrese una Fecha'});
                            } else if (!values.Total || values.Total <= 0) {
                                ErrorAlert({text: 'Por favor ingrese un Monto v?lido'});
                            } else {
                                createPayment();
                            }
                            }}
                            value={values.UUID}
                        >
                                <div>
                                    Guardar
                                </div>            
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default CreateClientForm;
