import { gql } from "@apollo/client";

export const USERADMINLOGIN = gql`
mutation USERADMINLOGIN($input: userLogin_input) {
  USERADMINLOGIN(input: $input) {
    Acceso
    correo
    img
    nombre
  }
}
`

export const GET_MOVIMIENTOSPENDIENTES = gql`
query GET_MOVIMIENTOSPENDIENTES {
  GET_MOVIMIENTOSPENDIENTES {
    Nombre
    IMSS
    tipo
    Empresa
    LlaveIMSS
    ContrasenaIMSS
    envioIDSE
    certificadoPass
    userIMSS
    Respuesta
    id
    RFCpatron
    RP
    NSS
    primerApellido
    segundoApellido
    nombre
    SBC
    tipoTrabajador
    tipoSalario
    semanaJornada
    fechaMovimiento
    CURP
    causaBaja
    solicitudIDSE
  }
}
`


export const GET_MOVIMIENTOSNOPROCESADOS = gql`
query GET_MOVIMIENTOSNOPROCESADOS {
  GET_MOVIMIENTOSNOPROCESADOS {
    id
    Nombre
    IMSS
    tipo
    Empresa
    LlaveIMSS
    ContrasenaIMSS
    envioIDSE
    certificadoPass
    userIMSS
    Respuesta
    Comentario
    fechaNoProcesado
    RFCpatron
    RP
    NSS
    primerApellido
    segundoApellido
    nombre
    fechaMovimiento
    CURP
    causaBaja
  }
}
`

export const GET_MOVIMIENTOSENVIADOS = gql`
query GET_MOVIMIENTOSENVIADOS {
  GET_MOVIMIENTOSENVIADOS {
    Nombre
    IMSS
    tipo
    Empresa
    LlaveIMSS
    ContrasenaIMSS
    envioIDSE
    certificadoPass
    userIMSS
    Respuesta
    id
    RFCpatron
    RP
    NSS
    primerApellido
    segundoApellido
    nombre
    SBC
    tipoTrabajador
    tipoSalario
    semanaJornada
    fechaMovimiento
    CURP
    causaBaja
    solicitudIDSE
    respuestaIDSEEnviado
    respuestaIDSE
  }
}
`

export const GET_MOVIMIENTOSHISTORICOS = gql`
query GET_MOVIMIENTOSHISTORICOS {
  GET_MOVIMIENTOSHISTORICOS {
  id
  Nombre  
  IMSS 
  tipo
  Empresa 
  LlaveIMSS
  ContrasenaIMSS 
  envioIDSE 
  certificadoPass 
  Respuesta 
  userIMSS 
  respuestaIDSE 
  respuestaIDSEDos 
  RFCpatron
  RP 
  NSS 
  primerApellido 
  segundoApellido
  nombre 
  SBC 
  tipoTrabajador
  tipoSalario 
  semanaJornada 
  fechaMovimiento 
  CURP 
  causaBaja 
  }
}
`

export const GET_LAST_BILL = gql`
query GET_LAST_BILL {
  GET_LAST_BILL {
  id,
  Total,
  Mes,
  UUID,
  factura,
  XML,
  Fecha,
  idDispersion, 
  statusDispersion,
  comentarioDispersion,
  status,
  Total currentbebt,
  CostoColaborador,
  dateStart, 
  dateEnd,
  Company,
  resultadoSaldo,
  TotalColaboradores,
  TotalPago,
  SistemaActivo
  }
}`;

export const GET_ALL_PAYMENTS = gql`
query GET_ALL_PAYMENTS {
  GET_ALL_PAYMENTS {
    id
    Total
    Mes
    UUID
    factura
    XML
    Fecha
    idDispersion
    statusDispersion
    comentarioDispersion
    TotalCollaborators
  }
}`;

export const CREATE_PAYMENT = gql`
  mutation CREATE_PAYMENT($input: payments_Input) {
    CREATE_PAYMENT(input: $input)
  }
`;

export const GET_TRANSACTIONS_ADMIN = gql`
query GET_TRANSACTIONS_ADMIN($input: filtroAdmin) {
  GET_TRANSACTIONS_ADMIN(input: $input) {
    id
    Fecha
    Compania
    Empresa
    Dispersion
    Comission
    xml
    pdf
  }
}
`;