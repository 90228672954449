const Excel = require('exceljs');

export const CreateExcelFile = (link:any,type:string) => {
  console.log('Link',link)
    const workbook = new Excel.Workbook();
    let altaImss:any = null;
    let bajaImss: any = null;
    let modificacionesImss: any = null;
    const typesExcel:any=['alta','baja','modificaciondesalario']

  switch (link.tipo.toLowerCase().replace(/ /g, "")) {
      case typesExcel[0]:
        altaImss = workbook.addWorksheet("ALTA-REINGRESO");
      break;
      case typesExcel[1]:
        bajaImss = workbook.addWorksheet("BAJA")
      break;
      case typesExcel[2]:
        modificacionesImss = workbook.addWorksheet("MODIFICACIONES")
      break;  
    }

    const width = 40;

    const header = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
    };
    const headerFont = {
        name: 'Arial',
        color: { argb: 'FFFFFF' },
        family: 2,
        size: 11,
        bold: true,
    };

    switch (link.tipo.toLowerCase().replace(/ /g, "")) {
      case typesExcel[0]:
        altaImss.columns = [        
          { header: 'RFC del patron dictaminado', key: 'RFCpatron', width },
          { header: 'RP', key: 'RP', width },
          { header: 'NSS', key: 'NSS', width },
          { header: 'CURP', key: 'CURP', width },
          { header: 'Primer apellido', key: 'primerApellido', width },
          { header: 'Segundo apellido', key: 'segundoApellido', width },
          { header: 'Nombre(s)', key: 'nombre', width },          
          { header: 'SBC', key: 'SBC', width, style: { numFmt: '$#,##0.00;[Red]-$#,##0.00' } },
          { header: 'Tipo Trabajador', key: 'tipoTrabajador', width },
          { header: 'Tipo Salario', key: 'tipoSalario', width  },
          { header: 'Jornada', key: 'semanaJornada', width  },          
          { header: 'Fecha de movimiento', key: 'fechaMovimiento', width, style: { numFmt: 'dd/mm/yyyy' } },
        ];
      break;
      case typesExcel[1]:
        bajaImss.columns = [        
          { header: 'RFC del patron dictaminado', key: 'RFCpatron', width },
          { header: 'RP', key: 'RP', width },
          { header: 'NSS', key: 'NSS', width },
          { header: 'Primer apellido', key: 'primerApellido', width },
          { header: 'Segundo apellido', key: 'segundoApellido', width },
          { header: 'Nombre(s)', key: 'nombre', width },          
          { header: 'Causa Baja', key: 'causaBaja', width },          
          { header: 'Fecha de movimiento', key: 'fechaMovimiento', width, style: { numFmt: 'dd/mm/yyyy' } },
        ];
      break;
      case typesExcel[2]:
        modificacionesImss.columns = [        
          { header: 'RFC del patron dictaminado', key: 'RFCpatron', width },
          { header: 'RP', key: 'RP', width },
          { header: 'NSS', key: 'NSS', width },
          { header: 'Primer apellido', key: 'primerApellido', width },
          { header: 'Segundo apellido', key: 'segundoApellido', width },
          { header: 'Nombre(s)', key: 'nombre', width },
          { header: 'Fecha de movimiento', key: 'fechaMovimiento', width, style: { numFmt: 'dd/mm/yyyy' } },
          { header: 'SBC', key: 'SBC', width, style: { numFmt: '$#,##0.00;[Red]-$#,##0.00' } },
        ];
      break;  
    }
  
    let letter = 65;
    
    switch (link.tipo.toLowerCase().replace(/ /g, "")) {
      case typesExcel[0]:
        altaImss.columns.forEach(() => {
          altaImss.getCell(`${String.fromCharCode(letter)}1`).fill = header;
          altaImss.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
          altaImss.getCell(`${String.fromCharCode(letter)}1`).alignment = { horizontal: 'center' };
            letter++;
          });

        altaImss.addRow({
          RFCpatron:link.RFCpatron,
          RP: link.RP,
          NSS: link.NSS,
          primerApellido: link.primerApellido,
          segundoApellido: link.segundoApellido,
          nombre: link.nombre,
          SBC: link.SBC,
          tipoTrabajador:link.tipoTrabajador,
          tipoSalario:link.tipoSalario,
          semanaJornada: link.semanaJornada,
          fechaMovimiento: link.fechaMovimiento,
          CURP: link.CURP,
        });
      break;
      case typesExcel[1]:
        bajaImss.columns.forEach(() => {
          bajaImss.getCell(`${String.fromCharCode(letter)}1`).fill = header;
          bajaImss.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
          bajaImss.getCell(`${String.fromCharCode(letter)}1`).alignment = { horizontal: 'center' };
            letter++;
          });
        bajaImss.addRow({
          RFCpatron:link.RFCpatron,
          RP: link.RP,
          NSS: link.NSS,
          primerApellido: link.primerApellido,
          segundoApellido: link.segundoApellido,
          nombre: link.nombre,
          fechaMovimiento: link.fechaMovimiento,
          causaBaja: link.causaBaja
        });
      break;
      case typesExcel[2]:
        modificacionesImss.columns.forEach(() => {
          modificacionesImss.getCell(`${String.fromCharCode(letter)}1`).fill = header;
          modificacionesImss.getCell(`${String.fromCharCode(letter)}1`).font = headerFont;
          modificacionesImss.getCell(`${String.fromCharCode(letter)}1`).alignment = { horizontal: 'center' };
          letter++;
        });

        modificacionesImss.addRow({
          RFCpatron:link.RFCpatron,
          RP: link.RP,
          NSS: link.NSS,
          primerApellido: link.primerApellido,
          segundoApellido: link.segundoApellido,
          nombre: link.nombre,
          fechaMovimiento: link.fechaMovimiento,
          SBC: link.SBC,
        });
      break;  
    }
    
  workbook.xlsx.writeBuffer().then(function (data: Blob) {
        const blob = new Blob([data],
          { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = 'reporte_colaboradores_idse.xlsx';
        anchor.click();
        window.URL.revokeObjectURL(url);
      });
}

function ParseDate(input:any) {
    try{
        var parts = input.match(/(\d+)/g);
        let correctFormat = new Date(parts[0], parts[1]-1, parts[2]); 
        //return DateTime.format(correctFormat,'DD/MM/YYYY')
        console.log('Esto devuelvo :'+ correctFormat);
        return correctFormat;
     } catch (error) {
        console.log(error);
     }
  }
function ParseDate2(input:any, name:any, modulo:any) {
   if (input == null){
       console.log("Fecha nulla. Usuario: " + name + " en modulo " + modulo);
       return input;  
   } else if (!input.trim()) {
       console.log("Fecha vacía. Usuario: " + name + " en modulo " + modulo);
       return input;   
   } else {
       var strigDate = input;
       var date = new Date(strigDate);
       var useDate = date.toLocaleDateString('en-GB');
       return useDate;
   }
 }
 