import MovimientosIngresadosSection from "./MovimientosIngresadosSection";
import HistorialSection from "./HistorialSection";

const NewEnrollScreen = () =>{

  return(
    <>
      <MovimientosIngresadosSection />
      <HistorialSection />
    </>
  )
}

export default NewEnrollScreen;
