import Swal from "sweetalert2";
import iconoListo from '../../assets/modal_listo.svg'
import iconoAtencion from '../../assets/modal_atencion.svg'

export const SuccessfulAlert = ({title = '¡Éxito!', text = '', confirmButtonText = 'Entendido' }) => Swal.fire({
  color: '#222222',
  html: `
      <div class='contenedorIcono'>
        <div class='iconoAlerta'></div>
      </div>
      <div class='tituloAlertaDos'>
        ¡Listo!
      </div>
      <div class='textoAlertaDos'> ${text} </div>`,
  confirmButtonText: 'Entendido',
  confirmButtonColor: '#FABB00',
  buttonsStyling: true,
  allowOutsideClick: false,
  showCloseButton: true,
  focusConfirm: false,
  focusDeny: false,
  focusCancel: false
});


export const ErrorAlert = ({title ='¡Error!', text='', confirmButtonText='Entendido'})=> Swal.fire({
  color: '#222222',
  html: `
      <div class='contenedorIcono'>
        <div class='iconoAlerta'></div>
      </div>
      <div class='tituloAlertaDos'>
      ¡Error!
      </div>
      <div class='textoAlertaDos'> ${text} </div>`,
  confirmButtonText: 'Entendido',
  confirmButtonColor: '#FABB00',
  buttonsStyling: true,
  allowOutsideClick: false,
  showCloseButton: true,
  focusConfirm: false,
  focusDeny: false,
  focusCancel: false
})

export const AlertaComentario = ({tipo= '', nombre='', empresa='', fecha='', comentario=''})=> Swal.fire({
  html:`
  <div class='contenedorIcono'>
    <div class='iconoAlerta'></div>
  </div>
    <div class='tituloAlerta'>
      Movimiento no procesado
    </div>
    <div class='contenedortexto textoAlerta'>
      El movimiento <span class='textoresaltado'>${tipo}</span> del
      colaborador/a <span class='textoresaltado'>${nombre}</span> perteneciente a la empresa <span class='textoresaltado'>${empresa}</span>
      se marco como no procesado el dia <span class='textoresaltado'>${fecha}</span>
      con el siguiente comentario:
    </div>
    <div class='contenedorComentario'>
      <span class='textoAlerta'>${comentario}</span>
    </div>
    `,
    confirmButtonText: 'Entendido',
    confirmButtonColor: '#FABB00',
    allowOutsideClick: false,
    showCloseButton: true,
    focusConfirm: false,
    focusDeny: false,
    focusCancel: false,
})

export const AlertaEnviado = ({tipo='', nombre='', empresa='', comentario=''}) => Swal.fire({  html:`
  <div class='contenedorIcono'>
    <div class='iconoAlerta'></div>
  </div>
    <div class='tituloAlerta'>
      Movimiento no procesado
    </div>
    <div class='contenedortexto textoAlerta'>
      El movimiento <span class='textoresaltado'>${tipo}</span> del
      colaborador/a <span class='textoresaltado'>${nombre}</span> perteneciente a la empresa <span class='textoresaltado'>${empresa}</span>
      se marco como enviado, por favor ingrese el archivo respuesta para continuar

    </div>
    <div class='contenedorComentario'>
      <span class='textoAlerta'>${comentario}</span>
    </div>
  `,
  confirmButtonText: 'Entendido',
  confirmButtonColor: '#FABB00',
  allowOutsideClick: false,
  showCloseButton: true,
  focusConfirm: false,
  focusDeny: false,
  focusCancel: false,
})

export const AlertaFaltaRespuesta = ()=> Swal.fire({
  html:`
  <div class='contenedorIcono'>
    <div class='iconoAlerta'></div>
  </div>
    <div class='tituloAlerta'>
    ¡Este movimiento ya esta marcado como enviado!
    </div>
    <div class='contenedortextoDos'>
      <div class='textoAlerta'>
        Por favor ingresa el archivo respuesta para continuar
      </div>
    </div>
  `,
  confirmButtonText: 'Entendido',
  confirmButtonColor: '#FABB00',
  allowOutsideClick: false,
  showCloseButton: true,
  focusConfirm: false,
  focusDeny: false,
  focusCancel: false
})