import { 
  GoogleAuthProvider,
  signInWithPopup
 } from "firebase/auth"
import { auth } from "../../server/firebase"
import {Navigate, useNavigate} from 'react-router-dom'
import { loignUser } from "../../context/authContext/Actions"
import { useContext } from "react"
import AuthContext from "../../context/authContext/authContext"
import { validaUsuario } from "../../Services/auth/authService"
import { getToken } from "../../helpers/getToken"
import styles from './Login.module.css'


const LoginScreen = () => {
  const {state, dispatch} = useContext(AuthContext)
  const navigate = useNavigate()

  const sesion = getToken();
  console.log(sesion);
  if(sesion) return <Navigate to="/"/>

  const loginWithGoogle = () =>{
    const googleProvider = new GoogleAuthProvider()
    return signInWithPopup(auth, googleProvider)
  }

  const login = async () => {
    const resultado:any = await loginWithGoogle()
    const log = true
    const validacion =  await validaUsuario({Nombre: resultado.user.displayName, email: resultado.user.email, img: resultado.user.photoURL})
    if(validacion?.error){
      console.log(resultado,'tenemos error')
    }else{
      console.log(validacion, resultado);
      loignUser({logged:true,Nombre:resultado.user.displayName,Imagen: resultado.user.photoURL},dispatch)
      localStorage.setItem('nombre', resultado.user.displayName!)
      localStorage.setItem('img', resultado.user.photoURL!)
      localStorage.setItem('token', validacion.token!)
      navigate('/idse/notificaciones')
    }
  }

  return(
    <>
      <div className={styles.contenedor}>
        <div className={styles.contenedor__formulario}>
          <div className={styles.formulario}>
            <div
              className={styles.formulario__titulo}
              >
               ¡Bienvenid@ de vuelta!
            </div>
            <div
              className={styles.formulario__boton}
              onClick={login}>
              <div
                className={styles.formulario__icono_google}
                ></div>
                INICIAR SESI&Oacute;N CON GOOGLE
            </div>
            <div className={`${styles.formulario__boton} ${styles.formulario__segundoBoton}`}>
              <div className={styles.formulario__icono_Microsoft}></div>
               INICIAR SESI&Oacute;N CON MICROSOFT
            </div>
            <div className={styles.formulario__flex}>
              <div className={styles.formulario__linea}></div>
              <div>O</div>
              <div className={styles.formulario__linea}></div>
            </div>
            <div>
              <input
                type="text"
                placeholder="E-mail"
                className={`${styles.formulario__input} ${styles.primer__Input}`}
                />
            </div>
            <div>
              <input
                type="text"
                placeholder="Contraseña"
                className={`${styles.formulario__input}`}
                />
            </div>
            <div className={styles.formulario__olvidaste}>
              ¿Olvidaste tu Contrase&ntilde;a?
            </div>
              <button className={styles.boton}>
                Iniciar ses&iacute;on
              </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default LoginScreen

