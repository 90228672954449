import "./PageContent.css";

const PageContent = (props: any) => {
    return (
        <div className="pageContent">
            {props.children}
        </div>
    );
};

export default PageContent;