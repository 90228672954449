import styles from '../../../styles/Notificaciones/Notificaciones.module.css'

import { useContext, useEffect, useState } from 'react'
import File_Helper from '../../../helpers/File_Helper'
import { getDescargaArchivo, getMovimientosEnviados, getMovimientosPendientes, registraRespuestaIDSE, registraRespuestaIMSS } from '../../../Services/Movimientos/movimientosService'
import AuthContext from '../../../context/authContext/authContext'
import {saveAs} from 'file-saver'
import Swal from "sweetalert2";
import Historial from './HistorialSection'
import { cambioEstatus } from '../../../context/authContext/Actions'
import { useQuery } from '@apollo/client'
import { GET_MOVIMIENTOSENVIADOS } from '../../../querys/querys'
import { PageSection, ErrorAlert, SuccessfulAlert, Loading  } from '../../../components'
import { CreateExcelFile } from '../../../helpers/excel_alta'


const MovimientosIngresadosSection = () =>{
  const {state, dispatch} = useContext(AuthContext)
  const [movimientosEnviados, setMovimientosEnviados] = useState<any[]>([])
  const [estadoRespuesta, setEstadoRespuesta] = useState('')
  const [filtrado, setFiltrado] = useState<any[]>([])
  const [search, setSearch] = useState('')
  const [items, setItems] = useState<any[]>([])
  const [currentPage, setCurrentPage] = useState(0)
  const [elementosPagina, setElementosPagina] = useState(10)
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [loading, setLoading] = useState(false)

  const {data,error,loading:loadingMovimientos, startPolling, stopPolling} = useQuery(GET_MOVIMIENTOSENVIADOS)
  const resultado = data?.GET_MOVIMIENTOSENVIADOS
  console.log("Este es el resultado", resultado)

  const getMovimientos = async() =>{
    setMovimientosEnviados([...resultado])
    setLoading((current) => current = false)
  }

  useEffect(()=>{
    if(movimientosEnviados){
      guardaPaginado()
    }
  },[movimientosEnviados, elementosPagina])

  useEffect(()=>{
    console.log('MOVIMIENTOS INGRESADOS')
    getMovimientos()
  },[])


  const busqueda = (e:any) =>{
    setSearch(e.target.value)
    guardaPaginado()
  }

  const guardaPaginado = () =>{
    const results = !search ? movimientosEnviados : movimientosEnviados?.filter(
      (dato) => (dato.Nombre.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.Empresa.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.tipo.toLowerCase().includes(search.toLocaleLowerCase())
      || dato.IMSS.toLowerCase().includes(search.toLocaleLowerCase()))
    )
    setFiltrado((current) => current = results)
    setItems((current) => current = [...results].splice(0, elementosPagina))
    setNumeroPagina((current) => current = 1)
  }

  const siguientePagina = () => {
    const totalElementos = filtrado.length;
    const nextPage = currentPage +1
    const firstIndex =  nextPage * elementosPagina
    if(firstIndex === totalElementos) return;
    setItems((current) => current = [...filtrado].splice(firstIndex, elementosPagina))
    setCurrentPage((current)=> current = nextPage)
  }

  const paginaAnterior = () => {
    const prevPage = currentPage - 1
    if(prevPage < 0 ) return;
    const firstIndex = prevPage * elementosPagina
    setItems((current) => current = [...filtrado].splice(firstIndex, elementosPagina))
    setCurrentPage((current)=> current = prevPage)
  }

  const cambioPagina = (sentido:string) =>{
    if(sentido === 'Anterior'){
      let nuevaPagina = numeroPagina - 1
      if(nuevaPagina < 1){
        setNumeroPagina((current) => current = 1)
      }else{
        setNumeroPagina((current) => current = nuevaPagina)
      }
      paginaAnterior()
    }else{
      console.log('resultados',filtrado.length, (numeroPagina * elementosPagina) )
      if(filtrado.length <=(numeroPagina* elementosPagina)){
        return;
      }else{
      let nuevaPagina = numeroPagina + 1
        setNumeroPagina((current) => current = nuevaPagina)
        siguientePagina()
      }
    }
  }

  const defineElementosPagina = (valor: number)=>{
    setElementosPagina((current) => current = valor)
    setNumeroPagina((current) => current = 1)
  }

  const descargaArchivos = async(id:any,Tipo:any)=>{
    const result = await getDescargaArchivo({id,Tipo})
    const blob = new Blob([result.result[0]["Resultado"]], {type:'text/plain;charset=utf-8'})
    saveAs(blob, 'miarchivo.txt')
  }

  const respuestaIDSE = async(id:any, respuesta:any)=>{
    const result = await registraRespuestaIDSE({id,respuesta})
    setEstadoRespuesta((current) => current = respuesta)
    SuccessfulAlert({ text: `El movimiento se marco como ${respuesta}` });
  }

  const alertaFaltaRegistro = async()=>{
    ErrorAlert({text: 'Por favor seleccione una respuesta'})
  }

  useEffect(()=>{
    getMovimientos()
    startPolling(1000);
    return () =>{
      stopPolling()
    }
  },[resultado,startPolling, stopPolling])


  return(
    <PageSection>
      {(loading) ? <div className={styles.contenedorTablas}> <Loading /> </div>
      :
        <div className={styles.contenedorTablas}>
          <div className={styles.contenedorTituloTabla}>
            <div className={styles.tituloTabla}>
              Movimientos Ingresados
            </div>
            <div className={styles.contenedorInput}>
              <div> 
                <input
                  value={search}
                  className={styles.inputFiltrado}
                  type="text"
                  placeholder="buscar al colaborador por nombre"
                  onChange={busqueda}
                />
              </div>
              <div className={styles.iconoFiltro}></div>
            </div>
          </div>
          <div className={styles.contenedorTablaInterno}>
          <div className={styles.espacioTabla}>
          <table className={styles.tabla}>
            <thead>
              <tr>
                <td className={styles.columnaTablaDos}>Tipo de Movimiento</td>
                <td className={styles.columnaTablaDos}>Nombre Colaborador</td>
                <td className={styles.columnaTablaDos}>NSS</td>
                <td className={styles.columnaTablaDos}>Razon Social</td>
                <td className={styles.columnaTablaDos}>Documento del Movimiento</td>
                <td className={styles.columnaTablaDos}>Certificado</td>
                <td className={styles.columnaTablaDos}>Llave</td>
                <td className={styles.columnaTablaDos}>Usuario</td>
                <td className={styles.columnaTablaDos}>Contrase&ntilde;a</td>
                <td className={styles.columnaTablaDos}>Archivo</td>
                <td className={styles.columnaTablaDos}>Accion</td>
                <td className={styles.columnaTablaDos}>Respuesta</td>
              </tr>
            </thead>
            <tbody>
              {
                items?.map((movimiento)=>(
                  <tr className={styles.lineaTitulo}>
                    <td className={`${styles.columnaTexto}`}>{movimiento.tipo}</td>
                    <td className={`${styles.columnaTexto}`}>{movimiento.Nombre}</td>
                    <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{movimiento.IMSS}</td>
                    <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>{movimiento.Empresa}</td>
                    <td className={styles.columnaDatos}>
                      <div
                        className={styles.contenedorBoton}
                        onClick={()=> CreateExcelFile(movimiento, 'Activo')}
                      >
                        <div className={styles.iconoDocumento}></div>
                      </div>
                    </td>
                    <td className={styles.columnaDatos}>
                      <a href={movimiento.LlaveIMSS} className={styles.botonLink}>
                        <div className={styles.contenedorBoton}>
                          <div className={styles.iconoCertificado}></div>
                        </div>
                      </a>
                    </td>
                    <td className={styles.columnaDatos}>
                      <a href={movimiento.ContrasenaIMSS} className={styles.botonLink}>
                        <div className={styles.contenedorBoton}>
                          <div className={styles.iconoLlave}></div>
                        </div>
                      </a>
                    </td>
                    <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>
                      {movimiento.userIMSS}
                    </td> 
                    <td className={`${styles.columnaDatos} ${styles.columnaTexto}`}>
                      {movimiento.certificadoPass}  
                    </td>
                    <td className={styles.columnaDatos}>
                      <div className={styles.botonDescargaDocumento}
                      >
                        <div className={styles.iconoDescargarDocumento}></div>
                        <a
                          href={movimiento.respuestaIDSEEnviado}
                          target="_blank"
                          className={styles.textobotonDescargaDocumento}
                        >
                        Descargar documento
                        </a>
                      </div>
                    </td>
                    <td className={styles.botonesEstado}>
                      {(movimiento.Respuesta === 'Aceptado')?
                        <div
                          className={styles.iconoAceptadoDesactivado}
                        >
                        </div>
                        :
                        <div
                        className={styles.iconoAceptado}
                        onClick={()=> respuestaIDSE(movimiento.id,'Aceptado')}
                      >
                      </div>
                      }
                      {(movimiento.Respuesta === 'Rechazado')?
                        <div
                          className={styles.iconoRechazadoDesactivado}
                        >
                        </div>
                        :
                        <div
                        className={styles.iconoRechazado}
                          onClick={()=> respuestaIDSE(movimiento.id,'Rechazado')}
                        >
                      </div>
                    }
                    </td>
                    {(movimiento.Respuesta === 'Enviado')?
                  <td className={styles.columnaDatosDesactivado}>
                    <div
                      className={styles.contenedorBoton}
                      onClick={() => alertaFaltaRegistro()}
                      >
                      <div className={styles.iconoSubirRespuestaDesactivado}></div>
                    </div>
                  </td>
                  :
                <td className={styles.columnaDatos}>
                  <div className={styles.contenedorBoton}>
                      <File_Helper
                      name="ArchvivoRespuesta"
                      parametrofrom="ArchivoRespuesta"
                      idUsr={movimiento.id}
                      />
                    </div>
                    </td>
                    }
                  </tr>
                ))
              }
            </tbody>
          </table>
          </div>
          <div className={styles.contenedorPaginacion}>
            <div className={styles.columnaUnoPaginacion}>
              1-10 de 5
            </div>
            <div className={styles.columnaDosPaginacion}>
              <div
                className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}
                onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}>
                Mostrar por p&aacute;gina {elementosPagina}
                <div className={styles.iconoPaginacion}></div>
                <div className={styles.contenedorListaElementos}>
                {(muestraListaPaginacion === true)?
                <ul className={styles.listaElementos}>
                  <li onClick={() => defineElementosPagina(1)}>10</li>
                  <li onClick={() => defineElementosPagina(2)}>20</li>
                  <li onClick={() => defineElementosPagina(3)}>25</li>
                </ul>
                :
                null
                }
                </div>
                <div className={styles.columnaBotonesPaginacion}>
                  <div
                    className={styles.iconoPaginacionAnteriorActivo}
                    onClick={() => cambioPagina('Anterior')}
                  ></div>
                  <div className={styles.textoPaginacion}>
                    {numeroPagina}/{Math.ceil(filtrado.length/elementosPagina)}
                  </div>
                  <div
                    className={styles.iconoPaginacionSiguienteActivo}
                    onClick={() => cambioPagina('Siguiente')}
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      }
     </PageSection>
  )
}

export default MovimientosIngresadosSection