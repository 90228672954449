import { initializeApp } from 'firebase/app';
import {getAuth, signInWithEmailAndPassword } from "firebase/auth"

const firebaseConfig = {
    apiKey: "AIzaSyAMbYneoQjkjgsuvEVt-AMcNkYPNhBnCpo",
    authDomain: "itseekers-1582214344227.firebaseapp.com",
    databaseURL: "https://itseekers-1582214344227.firebaseio.com",
    projectId: "itseekers-1582214344227",
    storageBucket: "itseekers-1582214344227.appspot.com",
    messagingSenderId: "1060099751666",
    appId: "1:1060099751666:web:17953adf7459040c629a6b",
    measurementId: "G-DJSG0GBX0G",
};
// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const signInWithEmailPassword = signInWithEmailAndPassword;