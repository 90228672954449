import "./PageSection.css";

const PageSection = (props: any) => {
    return (
        <section className="PageSection">
            {props.children}
        </section>
    );
};

export default PageSection;