import axios from 'axios';
import { auth } from '../../server/firebase';

type loginGoogleAdmin={
  Nombre: string|null,
  email: string|null,
  img: string| null
}

export const validaUsuario = async (user: loginGoogleAdmin) =>{
  let res = await axios.put('/auth/google', user)
  .then(res=>{
    return res.data.data
  })
  .catch((err)=>{
    const { error } = err.response?.data || false;
    console.log(err)
    return {
      error: error ? error : err,
      status: err.response?.status | 500
  };
  })
  return res
}

export const getUserSesion = async(email:string)=>{
  let res = await axios.get(`/auth/getUser/?email=${email}`)
  .then(res =>{
    console.log('resultado', res)
    return res.data.data.result
  })
  .catch((err)=>{
    const{error} = err.response?.data || false;
    console.log(err)
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  })
  return res
}

export const loginGoogleService = async (user: loginGoogleAdmin) => {

  let res = await axios.put('/auth/google/', user)
      .then(res => {
          res.data.status = res.status;
          return res.data
      })
      .catch((err) => {
          const { error } = err.response?.data || false;

          return {
              error: error ? error : err,
              status: err.response?.status | 500
          };

      });

  return res;
};

export const renewTokenService = async () => {

  const token = localStorage.getItem('token');

  // Send token in headers when page or browser is reload
  if (token) axios.defaults.headers.common['x-token'] = token;

  let res = await axios.get('/auth/renew')
      .then(res => res.data)
      .catch((err) => {
          const { error } = err.response?.data || false;

          return {
              error: error ? error : "Ocurrio un error con el servidor.",
              status: err.response?.status | 500
          };
      });

  return res;
};

export const logoutService = async () => {
  const res = await axios.get('/auth/logout')
  .then(res => res)
  .catch((err) => {
      const { error } = err.response?.data || false;

      return {
          error: error ? error : "Ocurrio un error con el servidor.",
          status: err.response?.status | 500
      };
  });

  localStorage.removeItem('token');
  auth.signOut();

  return res;
}
