import Swal from "sweetalert2";
import { cambioEstatus } from "../../context/authContext/Actions";
import { postNoProcesado } from "../../Services/Movimientos/movimientosService";
import { SuccessfulAlert } from "./Alerta";


const AlertaMensaje  =(id:any, tipo:string, nombre:string, empresa:string, setEstadoRespuesta:any, dispatch:any, state:any,setMovimientosPendientes:any, movimientosPendientes:any)=>{
  Swal.fire({
        html: `
        <div class='contenedorIcono'>
          <div class='iconoAlerta'></div>
        </div>
        <div class='tituloAlerta'>
          Movimiento no procesado
        </div>
        <div class='contenedortexto textoAlerta'>
          El movimiento <span class='textoresaltado'>${tipo}</span> del
          colaborador/a <span class='textoresaltado'>${nombre}</span> perteneciente a la empresa <span class='textoresaltado'>${empresa}</span>
          se marcar&aacute; como <span class='textoresaltado'>NO PROCESADO</span>
        </div>`,
        input:"textarea",
        inputPlaceholder: "Por favor escribre el motivo",
        showCancelButton: true,
        confirmButtonText: "Guardar",
        allowOutsideClick: false,
        cancelButtonText: "Cancelar",
        confirmButtonColor: '#FABB00',
        inputValidator: nombre => {
          if (!nombre) {
              return "Por favor escribe el motivo";
          } else {
              return "";
          }
      }
    })
    .then(resultado => {
        if (resultado.value) {
          let nombre = resultado.value;
          console.log(nombre, id)
            postNoProcesado({id:id,Comentario:nombre})
            SuccessfulAlert({text: 'El movimiento se marco como No procesado'})
            cambioEstatus({cambioEstatus:true},dispatch)
            let nuevosMovimientos =  movimientosPendientes.filter((dato:any) => dato.id !== id)
            setMovimientosPendientes([...nuevosMovimientos])
        }
    });
}

export default AlertaMensaje

