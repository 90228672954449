import { Link, useLocation } from "react-router-dom"
import "./TabItem.css";

const TabItem = (props: any) => {
    const {pathname} = useLocation()

    return (
      <>
        <Link
          to={props.to}
          className={`${pathname.includes(props.to)? 'linkActivo': 'linkInactivo'}`}
        >
          {props.children}
        </Link>
      </>
    );
  };

export default TabItem;
