import { useEffect, useState } from 'react';
import "./SideBar.css";
import LinkSideBar from "./LinkSideBar";

const SideBar = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentModule, setCurrentModule] = useState<string | null>('');

  const changeMenu = () => {
    if (isOpen) {
      document.documentElement.style.setProperty('--widthMenu', '50px');
      document.documentElement.style.setProperty('--marginLi', '0');
      document.documentElement.style.setProperty('--visibleText', 'hidden');
      document.documentElement.style.setProperty('--heightA', '0px');
      document.documentElement.style.setProperty('--widthLi', '60%');
      document.documentElement.style.setProperty('--marginRightIconMenu', '0');
      document.documentElement.style.setProperty('--locationIconMenu', 'center');

      const meeting = document.querySelectorAll('#meeting');
      meeting.forEach((meet) => {
          const p = document.querySelector('#textHelp')
          if(p){
              meet.removeChild(p)
          }
      });

      setIsOpen(false);
    } else {
      document.documentElement.style.setProperty('--widthMenu', 'auto');
      document.documentElement.style.setProperty('--marginLi', '0 6px 9px 0');
      document.documentElement.style.setProperty('--visibleText', 'visible');
      document.documentElement.style.setProperty('--heightA', 'none');
      document.documentElement.style.setProperty('--widthLi', 'none');
      document.documentElement.style.setProperty('--marginRightIconMenu', '-2vh');
      document.documentElement.style.setProperty('--locationIconMenu', 'flex-end');
      const meeting = document.querySelectorAll('#meeting');

      meeting.forEach((meet) => {
          const p = document.createElement('p')
          p.textContent = "Ayuda"
          p.id="textHelp"
          meet.appendChild(p)
      });
      
      setIsOpen(true);
    }
  };

  useEffect(() => {
      setCurrentModule(localStorage.getItem('currentModule'));
  }, []);

  const handleActiveModule = (name: string) => {
      localStorage.setItem('currentModule', String(name));
      setCurrentModule(String(name));
  }

  const communObj = {
    handleActiveModule,
    currentModule,
  };

  return (
      <nav className="RectangleSideBar">       
          <ul>
              <li id="IconMenu">
                  <img alt="Dashbaord" onClick={changeMenu} />
              </li>
              {
                //CheckPermission("Sidebar.Dashboard")?
                <LinkSideBar _id="configPayrollIcon" name="IDSE" path="/idse/notificaciones"  {...communObj} />
                //:null
              }
              {
                //CheckPermission("Sidebar.Dashboard")?
                <LinkSideBar _id="empresaIcon" name="Clientes" path="/companies"  {...communObj} />
                //:null
              }
              {
                //CheckPermission("Sidebar.Dashboard")?
                <LinkSideBar _id="colaboradoresIcon" name="Chat"  {...communObj} />
                //:null
              }
              {
                <LinkSideBar _id="centralPagosIcon" name="Central de Pagos" {...communObj} path="/centralpagos" />
              }
          </ul>
      </nav>
  )
};

export default SideBar;
