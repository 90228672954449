import "./MainContainer.css";

const MainContainer = (props: any) => {
    return (
        <main className="mainContainer" style={props.style}>
            {props.children}
        </main>
    );
};

export default MainContainer;
