import "./ToolBar.css";

const ToolBar = (props: any) => {
    return (
        <div className="ToolBarContainer">
            {props.children}
        </div>
    );
};

export default ToolBar;
