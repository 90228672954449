import {
  useEffect,
  useState,
  useContext
} from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import Grid from '@mui/material/Grid';
import { server } from './server/server'

import {
  HeaderPrincipal,
  SideBar,
  MainContainer,
} from "./components"
import { auth } from './server/firebase';
import AuthContext from './context/authContext/authContext';
import { startLogin, startLogout } from './actions/auth';

import LoginScreen from './Screens/Login/LoginScreen'
import IdseScreen from './Screens/Idse/IdseScreen'
import NewMovementsScreen from './Screens/Idse/NewMovements/NewMovementsScreen';
import NewEnrollScreen from './Screens/Idse/NewEnroll/NewEnrollScreen';
import PaymentScreen from './Screens/Payment/PaymentScreen';
import ClientScreen from './Screens/Payment/Client/ClientScreen';
import CompaniesScreen from './Screens/Companies/CompaniesScreen';

import AuthState from './context/authContext/AuthState'
import ProtectedRouter from './helpers/ProtectedRoute'
import CentralPagosScreen from './Screens/CentralPagos/CentralPagosScreen';
import CentralPagos from './Screens/CentralPagos/CentralPagos';

axios.defaults.baseURL = server;
axios.defaults.withCredentials = true;

function App() {
  const [clas, setClas] = useState('BodyContainer');
  const {pathname} = useLocation()
  const loginStyles = !pathname.includes('/login') ? {} : {
    minHeight: '100vh',
    paddingBottom: '0',
  }

  useEffect(() => {
    if (window.location.pathname !== '/') {
        setClas('BodyContainer BodyContainerWhite');
        document.documentElement.style.setProperty('--color', '#000');
        document.documentElement.style.setProperty('--backGroundRectangle', '#fff');
        document.documentElement.style.setProperty('--lineColor', '#e7e7e7');
    } else {
        document.documentElement.style.setProperty('--color', '#fff');
        document.documentElement.style.setProperty('--backGroundRectangle', '#154565');
        document.documentElement.style.setProperty('--lineColor', '#154565');
    }
  }, []);


  return (
    <>
      { (!pathname.includes('/login')) &&
        <HeaderPrincipal />
      }
      <Grid direction="row" container item>
          { (!pathname.includes('/login')) &&
            <Grid xs="auto" item className="RectangleSideBar">
              <SideBar />
            </Grid>
          }
        <Grid xs item className={clas} style={loginStyles}>
          <MainContainer style={loginStyles}>
            <AuthState>
              <Routes>
                <Route path="/" element={ <Navigate to="/idse/notificaciones" replace /> } />
                <Route path="/idse" element={
                    <ProtectedRouter>
                      <IdseScreen />
                    </ProtectedRouter>
                }>
                  <Route path='/idse/notificaciones' element={<NewMovementsScreen />}></Route>
                  <Route path='/idse/ingresados' element={<NewEnrollScreen />}></Route>
                </Route>
                <Route path="payment" element={
                  <ProtectedRouter>
                    <PaymentScreen />
                  </ProtectedRouter>
                }>
                  <Route path='/payment/:db/:companyId' element={<ClientScreen />}></Route>
                </Route>
                <Route path="/centralPagos" element={
                  <ProtectedRouter>
                    <CentralPagosScreen />
                  </ProtectedRouter>
                }>
                  <Route path='/centralPagos' element={<CentralPagos />}></Route>
                </Route>
                <Route path="companies" element={<CompaniesScreen />}></Route>
                <Route path="login" element={<LoginScreen />}></Route>
              </Routes>
            </AuthState>
          </MainContainer>
        </Grid>
      </Grid>
      { (!pathname.includes('/login')) &&
        <footer>
          <span>&nbsp;  @Bits</span>
        </footer>
      }

    </>
  );
}

export default App
