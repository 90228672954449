import { Link, useLocation } from "react-router-dom"
import _ from 'lodash';
import "./TabContainer.css";
import TabItem from "../TabItem/TabItem";

// TODO: Refactor pathname includes. We can take the index of tabItems

const TabContainer = (props: any) => {
    const {pathname} = useLocation();
    const tabItems = _.get(props, 'items', []);

    return (
      <>
        <div className={'contenedorRutas'}>
            {
                tabItems.map( (item: { to: any; DisplayComponent: any; }) => {
                    const { to, DisplayComponent} = item;
                    return (<TabItem to={to}><DisplayComponent /></TabItem>);
                })
            }
         {props.children}
        </div>
        <div className={'lineaUno'}>
          <div
            className={`
                ${'lineaInterna'}
                ${pathname.includes('notificaciones')? 'lineaInternaUno' : 'lineaInternaDos'}
            `}
          ></div>
        </div>
      </>
    );
};

export default TabContainer;