import { PageSection } from "../../components"
import CentralPagosList from "./CentralPagosListSection"

const CentralPagos = () =>{
  return(
    <>
      <PageSection>
        <CentralPagosList />
      </PageSection>
    </>
  )
}

export default CentralPagos;