import { useEffect, useState, } from 'react';
import styles from '../../styles/CentralPagos/CentralPagos.module.css';
import _ from 'lodash';
import { getTransactions, uploadFilesTransactions, sendEmailTransactions } from '../../Services/transactions/transactions.services';
import iconoPDF from "./../../assets/svg/icono_descargar_pdf.svg";
import descargaXml from "./../../assets/svg/icono_descarga_xml.svg";
import { postFileAWS } from '../../Services/Movimientos/movimientosService';
import { ErrorAlert, SuccessfulAlert } from '../../components';
import FileInput from '../../helpers/FileInput';
import Swal from 'sweetalert2';

const CentralPagosListSection = () =>{
  const [elementosPagina, setElementosPagina] = useState(10)
  const [numeroPagina, setNumeroPagina] = useState(1)
  const [mainData, setMainData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [muestraListaPaginacion, setMuestraListaPaginacion] = useState(false)
  const [isCheck, setIsCheck] = useState<any[]>([]);
  const [pdf, setPDF] = useState();
  const [xml, setXML] = useState();
  const [emails, setEmails] = useState('');

  useEffect(()=>{
    getTransactionsData()
  },[]);

  const getTransactionsData = async()=>{
    const filtro = ''
    const result = await getTransactions(filtro)
    setMainData(result.data)
    setItems(result.data)
  }

  const filterFunction = (mainData: any[], searchTerm: String,) => {
    let expresion = new RegExp(`${searchTerm}.*`, "i");
    const nuevofilteredData = mainData.filter((lis: any) =>
      expresion.test(lis.Compania) || expresion.test(lis.Empresa)
    );
    return nuevofilteredData;
  };

  ///Start Commun functionalities

  const defineElementosPagina = (valor: number)=>{
    setElementosPagina((current) => current = valor)
    setNumeroPagina((current) => current = 1)
  }

  const cambioPagina = (direction: "next" | "back") => {
    let nuevaPagina = 1;
    if(direction === 'back') {
      nuevaPagina = numeroPagina - 1 < 1 ? 1 : numeroPagina - 1;
    } else if (direction === 'next'){
        nuevaPagina = mainData.length < (numeroPagina* elementosPagina) ? numeroPagina + 1 : numeroPagina;
    }
    setNumeroPagina(nuevaPagina)
  }

  function getPaginatedItems(items: any, page: any, pageSize: any) {
    var pg = page || 1,
      pgSize = pageSize || 100,
      offset = (pg - 1) * pgSize,
      pagedItems = _.drop(items, offset).slice(0, pgSize);
    return {
      page: pg,
      pageSize: pgSize,
      total: items ? items.length : 0,
      total_pages: Math.ceil(items ? items.length : 0 / pgSize),
      data: pagedItems
    };
  }

  const search = (searchTerm: String) =>{
    let results = _.isNil(searchTerm) && _.isEmpty(searchTerm) ? mainData 
      : filterFunction(mainData, searchTerm);
    setItems(results);
    setNumeroPagina((current) => current = 1)
  }

  const renderPaginationDetails = () => {
    let pageInitial = (numeroPagina-1) * elementosPagina;
    let pageFinal = pageInitial + elementosPagina;
    if (pageFinal > items.length) {
      pageFinal = items.length
    }
    return (
      <>
        {`${pageInitial <= 0 ? 1 : pageInitial} - ${pageFinal} de ${items.length}`}
      </>
    );
  };

  const handleSelectAll = (e:any) =>{
    if(!e.target.checked){
      setIsCheck([]);
    }
    let filtradoSeleccionado = (mainData.filter((lis:any)=>{
      const flag = checkSelect(lis);
      return flag;
    }).map((lis:any) =>(lis.id)));
    setIsCheck(filtradoSeleccionado);
  }

  const handleClick =  (e:any) => {
    const {id, checked} = e.target;
    const selectedItem:any = items.filter((item:any)=>{
      return Number(item.id) === Number(id);
    })
    if(selectedItem.length > 0){
      if(!checked){
        setIsCheck(isCheck.filter((lis:any) => Number(lis.id) !== Number(id)))
      }else{
        setIsCheck([...isCheck, { id: Number(id), code: selectedItem[0].code}]);
      }
    }
  }

  const sendEmails = async () => {
    Swal.fire({title:'Subiendo archivos.'});
    Swal.showLoading();
    if(isCheck.length == 0){
      ErrorAlert({
        text: 'Es necesario seleccionar al menos 1 registro de la tabla.'
      });
    }else if( !pdf || !xml ){
      ErrorAlert({
        text: 'Es necesario subir un archivo PDF y XML para continuar.'
      });
    }else if(emails === ''){
      ErrorAlert({
        text: 'Es necesario ingresar al menos un correo electrónico.'
      });
    }else{
      for(const item of isCheck){
        const result_pdf = await postFileAWS({target:{files:pdf, name:'factura'}}, item.id, 'ArchivoENVIOIDSE-'+item.code, 'factura', true);
        item.pdf = result_pdf;
        const result_xml = await postFileAWS({target:{files:xml, name:'factura'}}, item.id, 'ArchivoENVIOIDSE-'+item.code, 'factura', true);
        item.xml = result_xml;
      }
      const result_email = await sendEmailTransactions({
        emails: emails,
        files: isCheck[0]
      });
      if(result_email.error){
        ErrorAlert({
          text: 'Ocurrió un error al subir los archivos.'
        });
      }else{
        const result = await uploadFilesTransactions({
          data: isCheck
        });
        if(result.error){
          ErrorAlert({
            text: 'Ocurrió un error al subir los archivos.'
          });
        }else{
          SuccessfulAlert({
            text: 'Se han enviado los archivos correctamente.'
          });
          getTransactionsData();
        }
      }
    }
  }

  const checkSelect = (item:any, checked=true) => {
    let flag = false;
    for(const checkItem of isCheck){
      if( checked && Number(checkItem.id) === Number(item.id) ){
        flag = true;
        break;
      }
    }
    return flag;
  }

  return(
    <>
      <div className={styles.contenedorPrincipal}>
        <div className={styles.contenedorHeader}>
          <div className={styles.contenedorFiltro}>
            <div className={styles.contenedorInput}>
              <input
                className={styles.inputFiltrado}
                type="text"
                placeholder="Ingresa la empresa o compañia"
                onChange={(e) => search(e.target.value)}
              />   
              <div className={styles.iconoFiltro}></div>
            </div>       
          </div>
          <div className={styles.contenedorBotones}>
            <table className={styles.tablaArchivos}>
              <thead>
                <tr className={styles.titulosTablaArchivos}>
                  <td className={styles.columnaInternaArchivosUno}>PDF</td>
                  <td className={styles.columnaInternaArchivosUno}>XML</td>
                  <td className={styles.columnaInternaArchivos}>Correos</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>                            
                    <FileInput
                      name="factura"
                      parametrofrom="ArchivoENVIOIDSE"
                      idUsr={0}
                      Desde= 'Enviado'
                      setArchives={setPDF}
                    />
                  </td>
                  <td>                           
                    <FileInput
                      name="factura"
                      parametrofrom="ArchivoENVIOIDSE"
                      idUsr={0}
                      Desde= 'Enviado'
                      accept={'.xml'}
                      setArchives={setXML}
                    />
                  </td>
                  <td>
                    <textarea className={styles.textAreaCorreos} placeholder="Ingrese los correos separados por comas (,)" onChange={(e)=>{setEmails(e.target.value)}}/>
                  </td>
                  <td>
                    <button
                        className={styles.botonDescargaDocumento}
                        onClick={sendEmails}
                        style={{    
                            width: "auto",
                            borderColor: "var(--color-amarillo-base)"
                        }}
                    >
                        <div className={styles.iconoPagado}></div>
                        <div className={styles.textoBotonPagado}>
                            Guardar
                        </div>            
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className={styles.contenedorTablaInterno}>
          <div className={styles.espacioTabla}>
            <table className={styles.tabla}>
              <thead>
                <tr>
                  <td className={`${styles.columnaTabla}`}>
                    <div id="colaboladores" className={styles.checkboxitem}>
                      <input
                        id="check"
                        type="checkbox"
                        onChange={(e) => handleSelectAll(e)}
                      ></input>
                      <label htmlFor="check"> Fecha </label>
                    </div>
                  </td> 
                  <td className={styles.columnaTabla}>Compania</td>
                  <td className={styles.columnaTabla}>Empresa</td>
                  <td className={styles.columnaTabla}>Dispersion</td>
                  <td className={styles.columnaTabla}>Comission</td>
                  <td className={styles.columnaTabla}>PDF</td>
                  <td className={styles.columnaTabla}>XML</td>                  
                </tr>
              </thead>
              <tbody>
                {
                  getPaginatedItems(items, numeroPagina , elementosPagina ).data?.map((item: any, key:number)=>(
                    <tr className={styles.lineaTitulo}>
                      <td className={`${styles.columnaTexto}`}>
                        <div id="colaboladores" className={styles.checkboxitem}>
                          <input
                            id={item?.id}
                            key={item?.id}
                            type="checkbox"
                            checked ={ checkSelect(item) }
                            onChange={(e) => handleClick(e)}
                            value={item?.id}
                            disabled={false}
                            ></input>
                          <label htmlFor={item?.id} className={styles.datosLabel}>  {item?.Fecha} </label>
                      </div>
                    </td>
                      <td className={`${styles.columnaTexto}`}>{item?.Compania}</td>
                      <td className={`${styles.columnaTexto}`}>{item?.Empresa}</td>
                      <td className={`${styles.columnaTexto}`}>{item?.Dispersion}</td>
                      <td className={`${styles.columnaTexto}`}>{item?.Comission}</td>
                      <td className={styles.columnaTexto}>
                        <div className={styles.contenedorBoton}>
                          <a
                            style={{ marginRight: "auto", marginLeft: "auto" }}
                            target="_blank"
                            href={item?.pdf}
                          >
                            <img
                              src={iconoPDF}
                              style={{ marginRight: "auto", marginLeft: "auto" }}
                              className="cursor"      
                              alt="DownloadFile"
                            />
                          </a>
                        </div>
                      </td>
                      <td className={styles.columnaTexto}>
                        <div className={styles.contenedorBoton}>
                          <a
                            style={{ marginRight: "auto", marginLeft: "auto" }} 
                            target="_blank"
                            href={item?.xml}
                          >
                            <img
                              src={descargaXml}
                              style={{ marginRight: "auto", marginLeft: "auto" }}
                              className="cursor"
                            />
                          </a>
                        </div>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          </div>
        <div className={styles.contenedorPaginacion}>
          <div className={styles.columnaUnoPaginacion}>
            {renderPaginationDetails()}
          </div>
          <div className={styles.columnaDosPaginacion}>
            <div
              className={`${styles.textoPaginacion} ${styles.columnaInternaPaginacion}`}
              onClick={() => setMuestraListaPaginacion(!muestraListaPaginacion)}
            >
              Mostrar por p&aacute;gina {elementosPagina}
              <div className={styles.iconoPaginacion}></div>
              <div className={styles.contenedorListaElementos}>
                {(muestraListaPaginacion === true)?
                  <ul className={styles.listaElementos}>
                    <li onClick={()=> defineElementosPagina(10)}>10</li>
                    <li onClick={()=> defineElementosPagina(20)}>20</li>
                    <li onClick={()=> defineElementosPagina(25)}>25</li>
                  </ul>
                  :null
                }
              </div>
            </div>
            <div className={styles.columnaBotonesPaginacion}>
              <div
                className={styles.iconoPaginacionAnteriorActivo}
                onClick={() => cambioPagina('back')}
              ></div>
              <div className={styles.textoPaginacion}>
                {numeroPagina}/{Math.ceil(items.length/elementosPagina)}
              </div>
              <div
                className={styles.iconoPaginacionSiguienteActivo}
                onClick={()=> cambioPagina('next')}
              >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  )
}

export default CentralPagosListSection;