import "./Loading.css";

const Loading = (props: any) => {
    return (
        <div className={"contenedorLoading"}>
          <div className={"loading"}></div>
        </div>
    );
};

export default Loading;
