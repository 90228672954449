import CompaniesSection from "./CompaniesSection";
import {
  PageTitle,
  PageContainer,
  PageContent,
  TabContainer,
} from "../../components";

const CompaniesScreen = () =>{

  return(
    <PageContainer>
      <PageTitle>Clientes</PageTitle>
      <PageContent>
        <CompaniesSection></CompaniesSection>
      </PageContent>
    </PageContainer>
  )
}

export default CompaniesScreen;
