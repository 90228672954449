import Swal from "sweetalert2"
import { cambioEstatus } from "../../context/authContext/Actions";
import { registraRespuestaIDSE } from "../../Services/Movimientos/movimientosService";
import { SuccessfulAlert } from "./Alerta";


const AlertaEnvioConfirma = (id:any, tipo:string, nombre:string, empresa: string, dispatch:any, setMovimientosPendientes:any, movimientosPendientes:any, setEstadoRespuesta:any, estadoRespuesta:any) =>{
  Swal.fire({
    html: `
    <div class='contenedorIcono'>
      <div class='iconoAlerta'></div>
    </div>
    <div class='tituloAlerta'>
      Enviar movimiento
    </div>
    <div class='contenedortexto textoAlerta'>
    El movimiento <span class='textoresaltado'>${tipo}</span> del
    colaborador/a <span class='textoresaltado'>${nombre}</span> perteneciente a la empresa <span class='textoresaltado'>${empresa}</span>
    se marcar&aacute; como <span class='textoresaltado'>ENVIADO</span>
    </div>
    <div class='textoAlerta'>
    Escribe <span class='textoresaltado'>CONFIRMAR</span> para continuar
    </div>
  `,
    input:"text",
    inputPlaceholder: "CONFIRMAR",
    showCancelButton: true,
    confirmButtonText: "Guardar",
    allowOutsideClick: false,
    cancelButtonText: "Cancelar",
    confirmButtonColor: '#FABB00',
    inputValidator: nombre => {
      if (nombre !== "CONFIRMAR") {
          return "Por favor escribe CONFIRMAR para continuar";
      } else {
          return "";
      }
    }
  }).then(resultado => {
    if(resultado.isConfirmed === true){
      SuccessfulAlert({text:"El movimiento se marco como enviado"})
      registraRespuestaIDSE({id,respuesta:'Enviado'})
      cambioEstatus({cambioEstatus:true}, dispatch)
      setEstadoRespuesta((current:any) =>  current = !estadoRespuesta)
    }
  })
}

export default AlertaEnvioConfirma