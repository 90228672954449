import {createContext, Dispatch} from 'react'

export type InitialStateType = {
  id: string,
  Nombre: string,
  Imagen: string,
  logged: boolean,
  cambioEstatus: boolean
}

type ModalPartial ={
  state: InitialStateType,
  dispatch: Dispatch<any>
}

export const initialState ={
  id: '',
  Nombre: '',
  Imagen: '',
  logged: false,
  cambioEstatus: true
}

export enum Types{
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  CAMBIO_ESTATUS = "CAMBIO_ESTATUS"
}

type PayloadAdmin =| InitialStateType;

export interface DispatchAdmin {
  payload?: PayloadAdmin
};

export enum AdminTypes {
  login = '[auth] Login',
  logout= '[auth] Logout',
};

const ModalInitalState ={
  state: initialState,
  dispatch: () => null
}

const AuthContext = createContext<ModalPartial>(ModalInitalState)

export default AuthContext