import { useContext } from "react"
import { SuccessfulAlert } from "../components"
import { cambioEstatus } from "../context/authContext/Actions"
import AuthContext from "../context/authContext/authContext"
import { postFileAWS, registraRespuestaIMSS } from "../Services/Movimientos/movimientosService"
import styles from '../styles/Notificaciones/Notificaciones.module.css'

const File_Helper = ({
  name,
  accept = ".pdf",
  parametrofrom = "",
  idUsr = "",
  idArchive = "",
  remove_file = false,
  setArchives,
  archives = [],
  disabled = false,
  section,
  Desde = ''
}:any)=>{

  const {state, dispatch} = useContext(AuthContext)

  const uploadFileAws = async(e:any):Promise<void> =>{
    console.log(e.target.value)
    let result = await postFileAWS(e, idUsr,parametrofrom,name,remove_file,section)
    console.log('Soy el resultado',result)
    await registraRespuestaIMSS({idMovimiento:idUsr, respuestaIDSE: result, Desde: Desde})
    await cambioEstatus({cambioEstatus:!state.cambioEstatus},dispatch)
    SuccessfulAlert({text: `El archivo respuestra se subio correctamente`})
  }

  return (
    <>
      <label  className={styles.botonsubirArchivos}>
      <input
          type="file"
          name={name}
          accept={accept}
          disabled={disabled}
          onChange={(e) => uploadFileAws(e)}
          className={styles.enviarArchivo}
        />
        <div className={styles.iconoSubirArchivo}>
        </div>
      </label>
    </>
  )

}

export default File_Helper