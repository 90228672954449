import { Dispatch } from "react";
import { Types } from "./authContext";

export const loignUser = ({logged = true, Nombre='',Imagen=''}:any,dispatch:Dispatch<any>):void =>{
  console.log(logged,Nombre,Imagen)
  dispatch({
    type:Types.LOGIN,
    payload:{
      logged,
      Nombre,
      Imagen
    }
  })
}

export const cambioEstatus = ({cambioEstatus}:any, dispatch:Dispatch<any>): void=>{
  console.log('cambioEstatus', cambioEstatus)
  dispatch({
    type:Types.CAMBIO_ESTATUS,
    payload:{
      cambioEstatus: cambioEstatus
    }
  })
}