import axios from 'axios';

interface PaymentCompany {
  UUID: string;
  PDF?: string;
  XML?: string;
  Fecha: string;
  db: string;
  totalColaboradores: number;
  Mes: string;
  Total: number;
}

export const getCompanies = async() =>{
  try{
    let res = await axios.get('/companies', {
      headers: {
        ['authorization']: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return res.data.data;
  }catch(err:any){
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}

export const getCompany = async(db:string, id:number) =>{
  try{
    let res = await axios.get('/companies/'+db+'/'+id, {
      headers: {
        ['authorization']: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return res.data;
  }catch(err:any){
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}

export const getCollaboratorCount = async(db:string) =>{
  try{
    let res = await axios.get('/companies/collaborators/'+db, {
      headers: {
        ['authorization']: `Bearer ${localStorage.getItem('token')}`
      }
    });
    return res.data;
  }catch(err:any){
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}

export const createPaymentCompany = async(data:PaymentCompany) =>{
  try{
    let res = await axios.post('/companies/payment', data, {
      headers: {
        ['authorization']: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'application/json'
      }
    });
    return res.data;
  }catch(err:any){
    const {error} = err.response?.data || false
    return{
      error: error ? error: err,
      status: err.response?.status | 500
    }
  }
}