import { Outlet } from "react-router-dom"
import {
  PageTitle,
  PageContainer,
  PageContent,
  TabContainer,
} from "../../components";

const HomeScreen = () =>{

  const tabItems = [
    {
      to: 'notificaciones',
      DisplayComponent: () => (<>Nuevos Movimientos</>),
    },
    {
      to: 'ingresados',
      DisplayComponent: () => (<>Movimientos Ingresados</>),
    },
  ];

  return (
    <PageContainer>
      <PageTitle>Movimientos IDSE</PageTitle>
      <TabContainer items={tabItems} />
      <PageContent>
          <Outlet />
      </PageContent>
    </PageContainer>
  );
}

export default HomeScreen;
