import { PageSection } from "../../../components";
import ClientList from "./ClientListSection";

const ClientScreen = () =>{

  return(
    <>
      <PageSection>
        <ClientList />
      </PageSection>
    </>
  )
}

export default ClientScreen;
