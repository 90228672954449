import { SuccessfulAlert } from "../components";
import { postFileAzurePDFPagos } from "../Services/Movimientos/movimientosService";
import styles from '../styles/Notificaciones/Notificaciones.module.css'


const FileUploaderxmlPagos = ({
  name,
  accept = ".xml",
  parametrofrom = "",
  idUsr = "",
  idArchive = "",
  remove_file = false,
  setArchives,
  archives = [],
  disabled = false,
  section,
  Desde = '',
  recoverFileURL,
  Cliente = '',
  Mes = ''
}:any)=>{

  const uploadFilepdfPagos = async(e:any):Promise<void> =>{
    let result = await postFileAzurePDFPagos(e, idUsr,parametrofrom,name,remove_file,section, Cliente, Mes)
    SuccessfulAlert({text: `El archivo XML se subio correctamente`})
    console.log('Este es el resultado antes',result)
    recoverFileURL(result);
  }

  return (
    <>
      <label className={styles.botonsubirArchivos}>
      <input
        type="file"
        name={name}
        accept={accept}
        disabled={disabled}
        onChange={(e) => uploadFilepdfPagos(e)}
        className={styles.enviarArchivo}
      />
      <div className={styles.iconoSubirArchivo}></div>
      </label>
    </>
  )
}


export default FileUploaderxmlPagos