import { SuccessfulAlert } from "../components"
import { postFileAWS } from "../Services/Movimientos/movimientosService"
import styles from '../styles/Notificaciones/Notificaciones.module.css'

const FileInput = ({
  name,
  accept = ".pdf",
  setArchives,
  disabled = false,
}:any)=>{

  const onChange = (e:any) => {
    setArchives(e.target.files);
  }

  return (
    <>
      <label  className={styles.botonsubirArchivos}>
      <input
          type="file"
          name={name}
          accept={accept}
          disabled={disabled}
          onChange={onChange}
          className={styles.enviarArchivo}
        />
        <div className={styles.iconoSubirArchivo}>
        </div>
      </label>
    </>
  )

}

export default FileInput
